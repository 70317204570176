import React from 'react';
import CustomButton from "../components/CustomButton"
import Header from '../components/Header'
import Footer from '../components/Footer'
import style from "../stylemodules/HomePage.module.css"
import Section from "../components/Section"
import "../styling/Page.css"
import image from "../images/forside.svg"

export default class HomePage extends React.Component {

  constructor(props) {
    super(props)
    localStorage.clear();
    this.bake_local("name", "Mit projekt")
  }

  bake_local(name, value) {
    window.localStorage.setItem(name, JSON.stringify(value));
  }

  

  
  componentDidMount() {
    console.log("Hawduiahwd")
    var focus = window.location.href
      var url = new URL(focus);
    var c = url.searchParams.get("debug");

    if (c != undefined) {
      sessionStorage['debug'] = true;
    }
  }
  

  render() {
    return (
      <div className="top_level">
        <Header site_number="0" />
        <div className="content">

          <Section width="60%" center="true">
          
            <h1 className={style.h1}>Velkommen</h1>
            <h2 className={style.h2}>til</h2>
            <h2 className={style.h2}>varmeinstallationers</h2>
            <h2 className={style.h2}>energieffektivitet</h2>
            <img className={style.img} alt="Forside billede" src={image}></img>
            <CustomButton className="button" text="Kom i gang" location="/BuildingInputPage" />
            
          </Section>

        </div>
        <Footer />
      </div>
    );
  }
}