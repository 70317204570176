import React from 'react';
import CustomButton from "../components/CustomButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Section from '../components/Section';
import HelpButton from '../components/HelpButton';
import CustomDropdown from '../components/CustomDropdown';
import CustomDropdown2 from '../components/CustomDropdown2';
import CustomYesNo from '../components/CustomYesNo';
import CustomInputField from '../components/CustomInputField';
import Box from '../components/Box'
import style from "../stylemodules/BuildingPage.module.css"


export default class BuildingInputPage extends React.Component {

  componentDidMount() {
    
    let data = this.read_local("house");
    let name = this.read_local("name");
    console.log(data)
    if (JSON.stringify(data) == 'null') {
      this.collectData();
      data = this.read_local("house");
      name = "Mit projekt"
    }
    this.setData(name, data);
  }

  collectData() {
    var data = {
      "heating_type": document.getElementById("heating_type").value,
      "house_insulation": document.getElementById("house_insulation").value,
      "house_windows": document.getElementById("house_windows").value,
      "house_ventilation": document.getElementById("house_ventilation").value,
      "living_input_area": parseFloat(document.getElementById("living_input_area").value),
      "bed_input_area": parseFloat(document.getElementById("bed_input_area").value),
      "bath_input_area": parseFloat(document.getElementById("bath_input_area").value),
      "living_temp": parseFloat(document.getElementById("living_temp").value),
      "bed_temp": parseFloat(document.getElementById("bed_temp").value),
      "bath_temp": parseFloat(document.getElementById("bath_temp").value),
      "living_heater": document.getElementById("living_heater").value,
      "bed_heater": document.getElementById("bed_heater").value,
      "bath_heater": document.getElementById("bath_heater").value
    }

    var data_pretty = {
      "heating_type": this.get_pretty("heating_type"),
      "house_insulation": this.get_pretty("house_insulation"),
      "house_windows": this.get_pretty("house_windows"),
      "house_ventilation": this.get_pretty("house_ventilation"),
      "living_input_area": this.get_pretty("living_input_area"),
      "bed_input_area": this.get_pretty("bed_input_area"),
      "bath_input_area": this.get_pretty("bath_input_area"),
      "living_temp": this.get_pretty("living_temp"),
      "bed_temp": this.get_pretty("bed_temp"),
      "bath_temp": this.get_pretty("bath_temp"),
      "living_heater": this.get_pretty("living_heater"),
      "bed_heater": this.get_pretty("bed_heater"),
      "bath_heater": this.get_pretty("bath_heater")
    }

    
    var project_name = document.getElementById("project_name").value

    if (project_name === "") project_name = "Mit projekt"

    this.bake_local("house", data);
    this.bake_local("house_pretty", data_pretty);
    this.bake_local("name", project_name);

  }

  get_pretty(id) {
    return document.getElementById(id).options[document.getElementById(id).selectedIndex].text
  }

  goto_front_page_ask() {
      if (window.confirm("Hvis du går til forsiden vil du miste dine indtastninger!")) {
        document.location.href = "/homePage"
      } 
    
  }

  setData(name, data) {
    document.getElementById("project_name").value = name
    document.getElementById("heating_type").value = data.heating_type;
    document.getElementById("house_insulation").value = data.house_insulation;
    document.getElementById("house_windows").value = data.house_windows;
    document.getElementById("house_ventilation").value = data.house_ventilation;
    
    document.getElementById("living_input_area").value = parseFloat(data.living_input_area);
    document.getElementById("bed_input_area").value = parseFloat(data.bed_input_area);
    document.getElementById("bath_input_area").value = parseFloat(data.bath_input_area);
    document.getElementById("living_temp").value = parseFloat(data.living_temp);
    document.getElementById("bed_temp").value = parseFloat(data.bed_temp);
    document.getElementById("bath_temp").value = parseFloat(data.bath_temp);
    document.getElementById("living_heater").value = data.living_heater;
    document.getElementById("bed_heater").value = data.bed_heater;
    document.getElementById("bath_heater").value = data.bath_heater;
  }

  bake_local(name, value) {
    window.localStorage.setItem(name, JSON.stringify(value));
  }

  read_local(name) {
    var result = window.localStorage.getItem(name)
    result && (result = JSON.parse(result));
    return result;
  }

  render() {

    const customButtonInlineStyle = { alignSelf: "center" };
    return (
      <div className="top_level">
        <Header site_number="1" />
        <div className="content">

          <Section width="60%">
          
            <h2 className={style.h2}>Huset</h2>
            <label className={style.name_label} style={{alignSelf: "flex-start"}}>Projekt navn<HelpButton help_id="label"></HelpButton></label>
            <input id="project_name" className={style.name_input} style={{alignSelf: "flex-start"}} type="text"></input>
            <div className={style.basis}>
              <CustomDropdown2 title="Opvarmning" id="heating_type" width="50%" label="outer">
                <option value="Fjernvarme" selected>Fjernvarme</option>
                <option value="Varmepumpe">Varmepumpe</option>
                <option value="Gas">Gaskedel</option>
                <option value="Piller">Pillefyr</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Isolering" id="house_insulation" width="50%" label="outer">
                <option value="Hoej">Høj</option>
                <option value="Middel" selected>Middel</option>
                <option value="Lav">Lav</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Vinduer" id="house_windows" width="50%" label="outer">
                <option value="AlmTermoruder" selected>Alm. termoruder</option>
                <option value="Energiruder">Energiruder</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Ventilation" id="house_ventilation" width="50%" label="outer" >
                <option value="Utaet">Utæt</option>
                <option value="Naturlig" selected>Naturlig</option>
                <option value="Mekanisk">Mekanisk</option>
              </CustomDropdown2>
              

            </div>
            <div className={style.basis}>
              <div style={{ width: "50%", minWidth: "154px" }}>
              <h3 className={style.h3}>Arealer<HelpButton help_id="input_area"></HelpButton></h3>
                <CustomDropdown2 title="Stue og køkken" id="living_input_area" width="100%">
                  <option value={30}>30 m&sup2;</option>
                  <option value={40}>40 m&sup2;</option>
                  <option value={50}>50 m&sup2;</option>
                  <option value={60} selected>60 m&sup2;</option>
                  <option value={70}>70 m&sup2;</option>
                  <option value={80}>80 m&sup2;</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Værelser" id="bed_input_area" width="100%">
                  <option value={40}>40 m&sup2;</option>
                  <option value={50}>50 m&sup2;</option>
                  <option value={60} selected>60 m&sup2;</option>
                  <option value={70}>70 m&sup2;</option>
                  <option value={80}>80 m&sup2;</option>
                  <option value={90}>90 m&sup2;</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Baderum" id="bath_input_area" width="100%">
                  <option value={5}>5 m&sup2;</option>
                  <option value={10} selected>10 m&sup2;</option>
                  <option value={15}>15 m&sup2;</option>
                  <option value={20}>20 m&sup2;</option>
                  <option value={25}>25 m&sup2;</option>
                  <option value={30}>30 m&sup2;</option>
                </CustomDropdown2>
              </div>
              <div style={{ width: "50%", minWidth: "154px" }}>
                <h3 className={style.h3}>Rumtemperatur<HelpButton help_id="temp2"></HelpButton></h3>
                <CustomDropdown2 title="Stue og køkken" id="living_temp" width="100%">
                  <option value={18}>18 °C</option>
                  <option value={19}>19 °C</option>
                  <option value={20}>20 °C</option>
                  <option value={21} selected>21 °C</option>
                  <option value={22}>22 °C</option>
                  <option value={23}>23 °C</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Værelser" id="bed_temp" width="100%">
                  <option value={18}>18 °C</option>
                  <option value={19}>19 °C</option>
                  <option value={20}>20 °C</option>
                  <option value={21} selected>21 °C</option>
                  <option value={22}>22 °C</option>
                  <option value={23}>23 °C</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Baderum" id="bath_temp" width="100%">
                  <option value={18}>18 °C</option>
                  <option value={19}>19 °C</option>
                  <option value={20}>20 °C</option>
                  <option value={21} selected>21 °C</option>
                  <option value={22}>22 °C</option>
                  <option value={23}>23 °C</option>
                </CustomDropdown2>
              </div>
              <div style={{ width: "50%", minWidth: "154px" }}>
              <h3 className={style.h3}>Varmegivere<HelpButton help_id="heater"></HelpButton></h3>
                <CustomDropdown2 title="Stue og køkken" id="living_heater" width="100%">
                  <option value="Radiatorer1Streng">Radiatorer 1-streng</option>
                  <option value="Radiatorer2Streng" selected>Radiatorer 2-streng</option>
                  <option value="LetGulvvarme">Let gulvvarme</option>
                  <option value="TungGulvvarme">Tung gulvvarme</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Værelser" id="bed_heater" width="100%">
                  <option value="Radiatorer1Streng">Radiatorer 1-streng</option>
                  <option value="Radiatorer2Streng" selected>Radiatorer 2-streng</option>
                  <option value="LetGulvvarme">Let gulvvarme</option>
                  <option value="TungGulvvarme">Tung gulvvarme</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Baderum" id="bath_heater" width="100%">
                  <option value="Radiatorer1Streng">Radiatorer 1-streng</option>
                  <option value="Radiatorer2Streng" selected>Radiatorer 2-streng</option>
                  <option value="LetGulvvarme">Let gulvvarme</option>
                  <option value="TungGulvvarme">Tung gulvvarme</option>
                </CustomDropdown2>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center"}}>
              <CustomButton class="button" text="Forrige" onClick={() => this.goto_front_page_ask()} />
              <CustomButton class="button" text="Næste" onClick={() => this.collectData()} location="/VVSDataPage" />
            </div>
            
          </Section>


        </div>
        <Footer/>
      </div>
    );
  }
}