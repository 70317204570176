import React from 'react';
import ReactDOM from 'react-dom';
import './styling/index.css';
//import './reset.css';
import RouterPage from './Pages/RouterPage';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

ReactDOM.render(<RouterPage/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
