import React from 'react';
import CustomButton from "../components/CustomButton"

export default class EndPage extends React.Component {

  render() {
    return (
      <div>
        <span>
            <CustomButton text="HomePage" location="/"/>
        </span>
      </div>
    );
  }
}