import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class CustomDropdown extends React.Component {
    constructor(props) {
        super(props);
    }
    render() { 
        return (
            <div class="outerBox" visible={this.props.visible}>
                <label class="innerlabel">{this.props.title}</label>
                <select class="dropdown" id={this.props.id} onChange={() => this.props.onChange()}>
                    {this.props.children}
                </select>
            </div>
        );
    }
}