import React from 'react';
import CustomButton from "../components/CustomButton"
import Header from '../components/Header'
import Footer from '../components/Footer'
import style from "../stylemodules/BuildingPage.module.css"
import Section from "../components/Section"
import "../styling/Page.css"
import image from "../images/front_page.png"

export default class HelpPage extends React.Component {

  constructor(props) {
    super(props)
  }

  scrollIntoView(id) {
      var elem = document.getElementById(id)
      if (elem != null)
        elem.scrollIntoView({block: 'start', behavior: 'smooth'});
  }

 

  componentDidMount() {
      var focus = window.location.href
      var url = new URL(focus);
    var c = url.searchParams.get("title");
      console.log(c)
    this.scrollIntoView(c)
    }

  render() {
    return (
      <div className="top_level">
        <Header site_number="-1" />
        <div className="content">

          <Section width="60%">
            {/* 
            EDIT BELOW TO CHANGE THE HELP TEXT
            Supported tags are:
                a - for links to the same or a different page
                h2 - for headers
                p - for text
                img - for images
                ul - for defining a list
                li - for each element in the list
                
            id attribute is needed to be able to scroll to an element
            */}

            <h1 className={style.title}>Vejledning til VEFF</h1>
            <h1 className={style.helph1}>Huset</h1>
              <h2 id="project_name" className={style.h2}>Projekt navn</h2>
                <p className={style.helpp}>Angiv et sigende navn for huset og de vvs-funktioner du har fokus på.</p>
              <h2 id="heating_type" className={style.h2}>Opvarmning</h2>
                <p className={style.helpp}>Angiv hvilken varmekilde der er i huset. Hvis der skiftes varmekilde, er det den fremtidige varmekilde, som skal angives.</p>
              <h2 id="house_insulation"className={style.h2}>Isolering</h2>
                <p className={style.helpp}>Angiv husets isolering til enten lav, middel eller høj. Lav svarer til et typisk hus fra 30’erne med loftet efterisoleret til 100 mm isolering, efterisoleret hulmur og terrændæk med kondensisolering. Middel svarer til et typisk hus fra 60’erne eller 70’erne fx med 150 mm isolering på loftet, 100 mm isolering i ydervæggene og 100 mm isolering i terrændækket. Høj svarer til et nyere hus eller et efterisoleret hus med 250 mm isolering på loftet, 125 mm isolering i ydervæggene og 250 mm isolering i terrændækket.</p>
              <h2 id="house_windows" className={style.h2}>Vinduer</h2>
                <p className={style.helpp}>Angiv den primære vinduestype i huset. Alle vinduer antages at være af samme type med enten almindelige termoruder eller energiruder.</p>
              <h2 id="house_ventilation" className={style.h2}>Ventilation</h2>
                <p className={style.helpp}>Angiv ventilationen i huset. Utæt er et hus med stort naturligt luftskifte gennem utætheder, som giver et luftskifte i huset på 0,7 gange pr. time. Naturlig er et hus med udeluftventiler og aftrækskanaler samt evt. en emhætte, hvor med luftskiftet i huset er 0,5 gange pr. time. Mekanisk er et tæt hus med varmegenvinding på ventilationsluften og et luftskifte i huset på 0,5 gange pr. time.</p>
              <h2 id="input_area" className={style.h2}>Arealer</h2>
                <p className={style.helpp}>Angiv arealerne for hver af de tre zoner i huset. De tre arealer udgør tilsammen husets areal. Der ses bort fra en evt. kælder. Stue og køkken kan også omfatte fx et alrum, et bryggers eller en spisestue. Værelser omfatter typisk børneværelser og forældresoveværelse samt evt. gangarealer. Baderum kan også omfatte fx separate toiletter. Ved opdelingen i zoner, skal der også tages hensyn til, at der er ens varmegivere og temperaturregulering indenfor en zone.</p>
              <h2 id="temp2" className={style.h2}>Rumtemperaturer</h2>
                <p className={style.helpp}>Rumtemperaturen er den ønskede rumtemperatur i hver af de tre zoner i brugstiden. Det svarer til det, man indstiller på termostaten. Brugstiden er alle dage kl. 7:00 – 23:00.</p>
              <h2 id="heater" className={style.h2}>Varmegivere</h2>
                <p className={style.helpp}>Varmegiverne kan være 2- eller 1-strengede radiatorer, let eller tung gulvvarme. Tung gulvvarme er med rør indstøbt i et betonlag og gulvklinker. Let gulvvarme er med varmefordelingsplader og træ- eller laminatgulv. I rum med radiatorer er der lette gulv med træ- eller laminatgulve. Hvis der er flere zoner med radiatorer vil de normalt alle have samme 2- eller 1-strengede radiatorkobling.</p>



            <h1 className={style.helph1}>Rumopvarmning</h1>
              <h2 id="heater_rel_power" className={style.h2}>Varmegivereffekt (Varmefladeeffekt)</h2>
              <p className={style.helpp}>Varmegivereffekten angives i pct. for hver zone. Varmegivereffekten er et udtryk for varmegivernes maksimale varmeeffekt ved dimensionerende udetemperatur på -12 °C. Varmegivereffekten angives i forhold til behovet i husets tre zoner ved dimensionerende udetemperatur under hensyn til dimensionerende fremløbstemperatur og dimensionerende afkøling. En varmegivereffekt over 100 % betyder, at varmegivernes dimensionerende varmeeffekt er større end strengt nødvendigt, hvilket er en fordel ved lavtemperaturdrift. En varmegivereffekt under 100 % betyder, at varmegivernes dimensionerende effekt er for lille, hvilket er et problem for lavtemperaturdrift. Varmegivernes varmeeffekt kan bestemmes som beskrevet i Videnscenterets guide om Varmeafgiversystemer.</p>
              <p className={style.helpp}>Størrelserne for det dimensionerende varmetab ved -12ºC for de tre zoner kan indsættes i excel-værktøjet: </p>
              <a className={style.helpa} href="https://byggeriogenergi.dk/media/2377/beregning-af-varmeafgivere_ver2019.xlsm">Beregning af varmegivere</a>
              <p className={style.helpp}>Værktøjet beregner radiatoreffekten ift. dimensionerende varmetab og dette forhold kan indsættes i % i VEFF.</p>
              <h2 id="setback" className={style.h2}>Natsænkning</h2>
              <p className={style.helpp}>Natsænkning angiver, om der er sænkning af den ønskede rumtemperatur uden for brugstiden i de forskellige zoner i huset. Brugstiden er alle dage kl. 7:00 – 23:00. Sænkningen er på 2 °C i forhold til rumtemperaturen i brugstiden. Sænkningen opnås bedst ved automatisk styring af termostaterne, men kan også opnås ved systematisk manuel justering af termostatindstillingerne. Natsænkning i rummene kan kombineres med sænkning af varmekurven om natten.</p>
              <h2 id="heater_active" className={style.h2}>Åbne varmegivere (Åbne Varmekilder)</h2>
              <p className={style.helpp}>Åbne varmegivere angiver om alle varmegivere i zonen er åbne og har mulighed for at afgive fuld varmeeffekt. Ved radiatoropvarmning er det fx ikke nødvendigvis alle radiatorer i værelseszonen, som benyttes. For gulvvarme kan varmeeffekten fx være reduceret, fordi der er lagt tæpper på gulvene.</p>

              <h1 className={style.helph1}>Dimensionerende temperaturer</h1>
              <h2 id="forward" className={style.h2}>Fremløb</h2>
              <p className={style.helpp}>Den dimensionerende fremløbstemperatur er fremløbstemperaturen ved den dimensionerende udetemperatur på -12 °C. Den dimensionerende fremløbstemperatur til radiatorer er typisk 75-90 °C i ældre huse og 65-75 °C i huse fra 60’erne og 70’erne. I nye huse og ved nye varmeanlæg i eksisterende huse må den dimensionerende fremløbstemperatur højst være ca. 55 °C i henhold til DS 469 Varme- og køleanlæg i bygninger, som er en del af Bygningsreglementets krav. Den dimensionerende fremløbstemperatur til gulvvarme er typisk 40-45 °C i huse fra 60’erne og 70’erne, 35-40 °C i nye huse og 30-35 °C i lavenergihuse.</p>
              <h2 id="backward" className={style.h2}>Afkøling</h2>
              <p className={style.helpp}>Den dimensionerende afkøling er afkølingen af vandstrømmen i varmeanlægget ved den dimensionerende udetemperatur på -12 °C. Den dimensionerende afkøling er typisk 20 °C i et 2-strengs radiatoranlæg og 10 °C i et 1-strengs radiatoranlæg. Ved installation af fx fjernvarme eller kondenserende gaskedel på et ældre radiatoranlæg med høj dimensionerende fremløbstemperatur er det i nogle tilfælde muligt at forbedre driften ved at dimensionere for en større afkøling fx 30 °C. Den dimensionerende afkøling i gulvvarmeanlæg er typisk 10 °C.</p>

            <h1 className={style.helph1}>Tilslutninger</h1>
              <h2 id="heating_connection_pipes" className={style.h2}>Tilslutningsrør</h2>
              <p className={style.helpp}>Tilslutningsrørene fra varmeforsyningen til varmeanlægget bliver ofte udskiftet i forbindelse med installation af ny varmeforsyning. Hvis tilslutningsrørene er for små reduceres vandstrømmen til varmeanlægget og dermed også varmeafgivelsen fra varmegiverne i anlægget. For små tilslutningsrør antages typisk at reducere vandstrømmen til varmeanlægget med 20 % ved dimensionerende forhold.</p>
              <h2 id="heating_circulation_pump" className={style.h2}>Cirkulationspumpe</h2>
              <p className={style.helpp}>Valget af cirkulationspumpe i forhold til behovet i varmeanlægget påvirker vandstrømmen til anlægget og dermed også varmeafgivelsen fra varmegiverne i anlægget. For lille cirkulationspumpe antages typisk at reducere vandstrømmen til varmeanlægget med 20 % ved dimensionerende forhold. Stor cirkulationspumpe antages typisk at forøge vandstrømmen til varmeanlægget med 20 % ved dimensionerende forhold.</p>
              <h2 id="bypass_rel_flow_setpoint" className={style.h2}>Omløb (Bypass)</h2>
              <p className={style.helpp}>Omløb bruges til at sikre en vis minimumsvandstrøm gennem varmeforsyningsenheden og bruges typisk i fx gaskedler og varmepumper med lille vandindhold. Procenten angiver mindstevandstrømmen gennem varmeforsyningsenheden i forhold til den dimensionerende vandstrøm i varmeanlægget. 0 % svarer til, at der ikke er omløb.</p>

            <h1 className={style.helph1}>Indregulering</h1>
              <h2 id="system_balancing" className={style.h2}>Indreguleret</h2>
              <p className={style.helpp}>Ved indregulering af varmeanlægget justeres vandstrømmene ved dimensionerende forhold til det faktiske behov. Manglende indregulering antages typisk at reducere varmegivernes ydelse med 10 % og afkølingen af vandstrømmene i varmeanlægget med 20 %.</p>

            <h1 className={style.helph1}>Brugsvandsopvarmning</h1>
              <h2 id="dhw_consump" className={style.h2}>Varmtvandsforbrug</h2>
              <p className={style.helpp}>Middel varmtvandsforbrug svarer til et normalt forbrug for 2 voksne og 1-2 mindre børn. Stort varmtvandsforbrug svarer til et forbrug for 2 voksne og fx 2-3 meget badende teenagere.</p>
              <h2 id="temp" className={style.h2}>Varmtvandstemperatur</h2>
              <p className={style.helpp}>Varmtvandstemperaturen skal være mindst 55 °C for at undgå risiko for legionella. I nogle tilfælde indstilles der en højere varmtvandstemperatur fx for at være sikker på at have tilstrækkelig med varmt vand.</p>
              <h2 id="heater_size" className={style.h2}>Hedeflade</h2>
              <p className={style.helpp}>Hedefladen er det udvendige overfladeareal på rørspiralen i varmtvandsbeholderen. Hedefladens størrelse er normalt angivet i data for varmtvandsbeholderen. En hedeflade på 0,70 m² er typisk for varmtvandsbeholdere til traditionelle olie- eller gasopvarmede kedler. En hedeflade på 1,00 m² er typisk for traditionelle fjernvarmebeholdere og nyere varmtvandsbeholdere til kondenserende gaskedler. En hedeflade på 1,50 m² er typisk for nye fjernvarmebeholdere til lavtemperaturdrift.</p>

            <h1 className={style.helph1}>Fremløbstemperatur</h1>
              <h2 id="increase_rel" className={style.h2}>Hævning</h2>
              <p className={style.helpp}>Varmekurven indstiller fremløbstemperaturen i varmeanlægget i afhængighed af udetemperaturen. Varmekurvens maksimumtemperatur er i udgangspunktet varmeanlæggets dimensionerende fremløbstemperatur ved dimensionerende udetemperatur på -12 °C eller lavere. Hævningen af varmekurven i procent angiver, hvor meget højere eller lavere varmekurvens maksimumtemperatur er i forhold til differencen mellem dimensionerende fremløbstemperatur og rumtemperatur.</p>
              <h2 id="parallel_adjust_rel" className={style.h2}>Parallelforskydning</h2>
              <p className={style.helpp}>Parallelforskydning af varmekurven i procent hæver eller sænker hele varmekurvens forløb inklusive maksimumtemperaturen. Fremløbstemperaturen kan dog ikke blive højere end 90 °C. Procenten angives i forhold til differencen mellem dimensionerende fremløbstemperatur og rumtemperatur.</p>
              <h2 id="setback_rel" className={style.h2}>Natsænkning</h2>
              <p className={style.helpp}>Natsænkningen er den negative parallelforskydning af varmekurven udenfor husets brugstid i dagtimerne. Natsænkningen angives i procent af differencen mellem dimensionerende fremløbstemperatur og rumtemperatur. Natsænkningen kan, hvis den er tilstrækkelig stor, bruges til at lave central natsænkning af rumtemperaturerne i huset.</p>
              <h2 id="boost_rel" className={style.h2}>Morgenforcering</h2>
              <p className={style.helpp}>Morgenforceringen i procent er den positive parallelforskydning af varmekurven for at genopvarme huset op til beboerne står op om morgenen. Procenten angives i forhold til differencen mellem dimensionerende fremløbstemperatur og rumtemperatur.</p>

            <h1 className={style.helph1}>Fjernvarme</h1>
              <h2 id="dh_supply_temp_design" className={style.h2}>Fjernvarme maks. fremløbstemperatur</h2>
              <p className={style.helpp}>Fjernvarmens maksimale fremløbstemperatur er fjernvarmens fremløbstemperatur til huset ved udetemperatur på -12 °C eller lavere. I traditionelle fjernvarmesystemer er fjernvarmens maksimale fremløbstemperatur 95 °C eller højere. Maksimale fjernvarme fremløbstemperatur på 85 °C eller 75 °C svarer til øgende grad af lavtemperatur fjernvarme. Det er fast antaget, at fjernvarmens fremløbstemperatur falder lineært med stigende udetemperatur til en mindste fremløbstemperatur for fjernvarmen på 65 °C ved udetemperatur på 10 °C eller højere. Mindste fremløbstemperaturen på 65 °C er fastsat af hensyn til muligheden for at opvarme det varme brugsvand til den ønskede temperatur.</p>
              <h2 id="dh_exchanger_temp_drop_design" className={style.h2}>Vekslerstørrelse</h2>
              <p className={style.helpp}>Størrelsen på fjernvarmeveksleren, der leverer rumvarme, kan være almindelig eller stor. En almindelig vekslerstørrelse afkøler fjernvarmevandet til 30 % af differencen mellem fremløbstemperatur og returtemperatur i husets varmeanlæg. Hvis den aktuelle fremløbstemperatur i huset fra fjernvarmeveksleren til varmeanlægget er fx 60 °C, og returtemperaturen er 40 °C, bliver den aktuelle fjernvarme returtemperatur fra veksleren således 46 °C. En stor veksler vil afkøle fjernvarmevandet til 10 % af differencen mellem fremløbstemperatur og returtemperatur i varmeanlægget. I eksemplet vil det på det aktuelle tidspunkt give en fjernvarme returtemperatur på 42 °C. Værdierne er ved gennemsnitsvandstrømme i anlægget. </p>

            <h1 className={style.helph1}>Varmepumpe</h1>
              <h2 id="hp_cover_temp_design" className={style.h2}>Dækningstemperatur</h2>
              <p className={style.helpp}>Varmepumpens dækningstemperatur er den laveste udetemperatur ved hvilken varmepumpen selv kan dække husets samlede varmebehov til rumopvarmning og varmt brugsvand uden supplement fra fx elpatron eller elradiatorer. Ved opgørelsen ses der også bort fra solindfald og internt varmetilskud fra fx personer og apparater. I henhold til DS 469 Varme- og køleanlæg i bygninger, som er en del af Bygningsreglementets krav, skal varmepumpen mindst kunne dække varmebehovet ned til en udetemperatur på -7 °C.</p>
              <h2 id="hp_dhw_power_design_part" className={style.h2}>Større til varmt brugsvand</h2>
              <p className={style.helpp}>Procenten angiver, hvor meget større varmepumpen skal være for også at dække varmebehovet til opvarmning af varmt brugsvand ved den angivne dækningstemperatur for varmepumpen. Procenten til varmt brugsvand angives i forhold varmebehovet til rumopvarmning. Procenten til varmt brugsvand er mindst i ældre, dårligt isolerede huse, hvor der er et stort varmebehov til rumopvarmning, og størst i nye, velisolerede huse, hvor varmebehovet til rumopvarmning er mindre.</p>
              <h2 id="hp_min_rel" className={style.h2}>Modulationsgrænse</h2>
              <p className={style.helpp}>Modulationsgrænsen angiver, hvor langt varmepumpen kan gå ned i ydelse ved kontinuerlig drift, før den begynder at køre on-off. Modulationsgrænsen angives i procent af varmepumpens nominelle ydelse ved den aktuelle udetemperatur. Modulationsgrænsen er normalt angivet i data for varmepumpen. Modulerende drift forudsætter, at varmepumpen er med frekvensomformer (inverter). Modulationsgrænsen er ofte ca. 50 %. En varmepumpe, som ikke er modulerende, angives til 100 %.</p>

            <h1 className={style.helph1}>Gaskedel</h1>
              <h2 id="gas_size" className={style.h2}>Kedelstørrelse</h2>
              <p className={style.helpp}>Kedelstørrelsen er kedlens maksimale ydelse i kW. Kedelstørrelsen er normalt angivet i data for kedlen.</p>
              <h2 id="gas_min_rel" className={style.h2}>Modulationsgrænse</h2>
              <p className={style.helpp}>Modulationsgrænsen angiver, hvor langt kedlen kan gå ned i ydelse ved kontinuerlig drift, før den begynder at køre on-off. Modulationsgrænsen angives i procent af kedelstørrelsen. Modulationsgrænsen er normalt angivet i data for kedlen. Modulationsgrænsen er ofte ca. 50 %. En kedel, som ikke er modulerende, angives til 100 %.</p>

            <h1 className={style.helph1}>Pillefyr</h1>
              <h2 id="pillet_size" className={style.h2}>Kedelstørrelse</h2>
              <p className={style.helpp}>Kedelstørrelsen er pillefyrets maksimale ydelse i kW. Kedelstørrelsen er normal angivet i data for pillefyret.</p>
              <h2 id="pillet_min_rel" className={style.h2}>Modulationsgrænse</h2>
              <p className={style.helpp}>Modulationsgrænsen angiver, hvor langt pillefyret kan gå ned i ydelse ved kontinuerlig drift, før den begynder at køre on-off. Modulationsgrænsen angives i procent af kedelstørrelsen. Modulationsgrænsen er normalt angivet i data for pillefyret. Modulationsgrænsen for pillefyr er ofte ca. 70 %. Et pillefyr, som ikke er modulerende, angives til 100 %.</p>

            {/* <img className={style.help_img} src="https://www.w3schools.com/images/colorpicker.gif"></img>
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <a className={style.a}  href="https://www.w3schools.com" >WDawdawjdnhiuawdiu</a>
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul}>
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 id="MyHelp"className={style.h2}>Min hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul> 
            <h2 className={style.h2}>Hjælp 1</h2>
            <p className={style.helpp}> Her er din hjælpetekst</p>
            <h2 className={style.h2}>Hjælp 2</h2>
            <ul className={style.ul} >
            <li>Coffee</li>
            <li>Tea</li>
            <li>Milk</li>
            </ul>  */}
          </Section>
        </div>
        <Footer />
      </div>
    );
  }
}