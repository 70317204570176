import React from 'react';
import style from "../stylemodules/HelpButton.module.css"

export default class HelpButton extends React.Component {
    constructor(props) {
        super(props);
    }


    render() { 

        let visible = (this.props.visible==false) ? "none" : "initial"

        return (
                <button className={style.button} style={{display: visible}}
                    onClick={ () =>  this.jump_to_help()}
                >?</button>
        );
    }
    
    jump_to_help() {
        if (this.props.help_id != null)
            window.open('/HelpPage?title=' + this.props.help_id, '_blank');
        else 
            window.open('/HelpPage', '_blank');
    }
}