import React from 'react';
import '../styling/CustomInput.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class CustomYesNo extends React.Component {
    constructor(props) {
        super(props);
    }
    render() { 
        return (
            <div>
                <div class="yesnoouter">
                    <p>{this.props.title}</p>
                    <div>
                        <label for={"yes " + this.props.name}>Ja</label>
                        <input type="radio" id={"yes " + this.props.name} name={this.props.name}/>
                        <label for={"no " + this.props.name}>Nej</label>
                        <input type="radio" id={"no " + this.props.name} name={this.props.name}/>
                    </div>
                    

                </div>
            </div>
        );
    }
}