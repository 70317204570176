import React from 'react';
import '../styling/Box.css';

export default class Box extends React.Component {
constructor(props) {
    super(props);
}
  render() {
    return (
      <div class="box_outer">
          <div class="box_children_list">
            {this.props.children}
          </div>
              
      </div>
    );
  }
}
