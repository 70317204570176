import React from 'react';
import CustomButton from "../components/CustomButton";
import Header from "../components/Header";
import Section from '../components/Section';
import CustomDropdown from '../components/CustomDropdown';
import CustomYesNo from '../components/CustomYesNo';
import CustomInputField from '../components/CustomInputField';

export default class OptionPage extends React.Component {

  render() {
    return (
      <div>
          <Header/>
          <Section title="Hus" logo="http://gratisskole.dk/sdata/minipic/002/00270.svg">
            <CustomDropdown title="Opvarmet areal" placeholder="Vælg areal">
              <option>
              1kvm
              </option>
            </CustomDropdown>
            <CustomDropdown title="Vinduer" placeholder="Antal vinduer">
              <option>
                10
              </option>
            </CustomDropdown>
            <CustomDropdown title="Varmeisoleringsniveau" placeholder="Vælg niveau">
              <option>
                a++
              </option>
            </CustomDropdown>
            <CustomDropdown title="Tæthed af klimaskærm" placeholder="Vælg tæthed">
              <option>
                a++
              </option>
            </CustomDropdown>
            <CustomInputField title="Beskrivelse af klimaskærm" placeholder="Beskrivelse">

            </CustomInputField>
          </Section>
          <Section title="Varmeinstallation" logo="http://gratisskole.dk/sdata/minipic/002/00270.svg">
          <CustomDropdown title="Type" placeholder="Vælg type">
              <option>
                420
              </option>
            </CustomDropdown>
            <CustomDropdown title="Setpunkt brugsvandstemperatur" placeholder="Vælg">
              <option>
                40deg
              </option>
            </CustomDropdown>
            <CustomDropdown title="Fremløbstemperatur centralvarme" placeholder="Vælg">
              <option>
                45deg
              </option>
            </CustomDropdown>
          </Section>
          <Section title="Varmefordelingssystem" logo="http://gratisskole.dk/sdata/minipic/002/00270.svg">
            <CustomDropdown title="Fordellingssystem" placeholder="Vælg fordellingssystem">
              <option>
                1kvm
              </option>
            </CustomDropdown>
            <CustomDropdown title="Radiatorer" placeholder="Vælg antal radiatorer">
              <option>
                vand
              </option>
            </CustomDropdown>
            <CustomDropdown title="Teremostatindstilling" placeholder="Vælg indstilling">
              <option>
                ECO
              </option>
            </CustomDropdown>
          </Section>
          <Section title="Varmt brugtvand" logo="http://gratisskole.dk/sdata/minipic/002/00270.svg">
            <CustomYesNo title="Integeret i kedel" name="kedel"></CustomYesNo>
            <CustomDropdown title="Varmt brugsvandstemperatur" placeholder="Vælg">
              <option>
                50deg
              </option>
            </CustomDropdown>
            <CustomDropdown title="Beholderstørrelse" placeholder="Vælg størrelse">
              <option>
                666
              </option>
            </CustomDropdown>
            <CustomDropdown title="Tappeprogram" placeholder="Vælg program">
              <option>
                5
              </option>
            </CustomDropdown>
          </Section>
          <Section title="Tilslutningsrør" logo="http://gratisskole.dk/sdata/minipic/002/00270.svg">
            <CustomDropdown title="Rørdimension" placeholder="Vælg dimension">
              <option>
                3"
              </option>
            </CustomDropdown>
            <CustomDropdown title="Længde indenfor klimaskærm" placeholder="Vælg længde">
              <option>
                69cm
              </option>
            </CustomDropdown>
            <CustomDropdown title="Rørisolering" placeholder="Vælg isoleringstype">
              <option>
                god
              </option>
            </CustomDropdown>
            <CustomDropdown title="Længde udenfor klimaskærm" placeholder="Vælg længde">
              <option>
                96cm
              </option>
            </CustomDropdown>
            <CustomYesNo title="Seperate rør til varmt brugsvand" name="brugsvand"></CustomYesNo>
            
          </Section>
          
          <CustomButton text="EndPage" location="/EndPage"/>
      </div>
    );
  }
}