import React from 'react';
import CustomButton from "../components/CustomButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Section from '../components/Section';
import CustomDropdown2 from '../components/CustomDropdown2';

import HelpButton from '../components/HelpButton';



import style from "../stylemodules/BuildingPage.module.css"

export default class BuildingInputPage extends React.Component {

  collectData() {
    var vvsData = {
      "room_heating": {
        "living_heater_rel_power": parseFloat(document.getElementById("living_heater_rel_power").value),
        "bed_heater_rel_power": parseFloat(document.getElementById("bed_heater_rel_power").value),
        "bath_heater_rel_power": parseFloat(document.getElementById("bath_heater_rel_power").value),
        "living_setback": ("true" === document.getElementById("living_setback").value),
        "bed_setback": ("true" === document.getElementById("bed_setback").value),
        "bath_setback": ("true" === document.getElementById("bath_setback").value),
        "living_heater_active": document.getElementById("living_heater_active").value,
        "bed_heater_active": document.getElementById("bed_heater_active").value,
        "bath_heater_active": document.getElementById("bath_heater_active").value
      },
      "design_temp": {
        "heating_system": {
          "rad_supply_temp_design": parseFloat(document.getElementById("rad_supply_temp_design").value),
          "floor_supply_temp_design": parseFloat(document.getElementById("floor_supply_temp_design").value),
          "rad1_temp_drop_design": parseFloat(document.getElementById("rad_temp_drop_design").value),
          "rad2_temp_drop_design": parseFloat(document.getElementById("rad_temp_drop_design").value),
          "floor_temp_drop_design": parseFloat(document.getElementById("floor_temp_drop_design").value)
        }
      },
      "system_balancing": ("true" === document.getElementById("system_balancing").value),
      "pipe_connection": {
        "heating_connection_pipes": document.getElementById("heating_connection_pipes").value,
        "heating_circulation_pump": document.getElementById("heating_circulation_pump").value,
        "bypass_rel_flow_setpoint": parseFloat(document.getElementById("bypass_rel_flow_setpoint").value)
      },
      "domestic_hot_water": {
        "dhw_consump": document.getElementById("dhw_consump").value,
        "temp": parseFloat(document.getElementById("temp").value),
        "heater_size": parseFloat(document.getElementById("heater_size").value)
      },
      "heat_supply": {
        "gas_size": parseFloat(document.getElementById("gas_size").value),
        "gas_min_rel": parseFloat(document.getElementById("gas_min_rel").value),
        "pillet_size": parseFloat(document.getElementById("pillet_size").value),
        "pillet_min_rel": parseFloat(document.getElementById("pillet_min_rel").value),
        "hp_cover_temp_design": parseFloat(document.getElementById("hp_cover_temp_design").value),
        "hp_dhw_power_design_part": parseFloat(document.getElementById("hp_dhw_power_design_part").value),
        "hp_min_rel": parseFloat(document.getElementById("hp_min_rel").value),
        "dh_supply_temp_design": parseFloat(document.getElementById("dh_supply_temp_design").value),
        "dh_exchanger_temp_drop_design": document.getElementById("dh_exchanger_temp_drop_design").value
      },
      "heating_curve": {
        "increase_rel": parseFloat(document.getElementById("increase_rel").value),
        "parallel_adjust_rel": parseFloat(document.getElementById("parallel_adjust_rel").value),
        "setback_rel": parseFloat(document.getElementById("setback_rel").value),
        "boost_rel": parseFloat(document.getElementById("boost_rel").value)
      }
    }

    var vvs_data_pretty = {
      "room_heating": {
        "living_heater_rel_power": this.get_pretty("living_heater_rel_power"),
        "bed_heater_rel_power": this.get_pretty("bed_heater_rel_power"),
        "bath_heater_rel_power": this.get_pretty("bath_heater_rel_power"),
        "living_setback": this.get_pretty("living_setback"),
        "bed_setback": this.get_pretty("bed_setback"),
        "bath_setback": this.get_pretty("bath_setback"),
        "living_heater_active": this.get_pretty("living_heater_active"),
        "bed_heater_active": this.get_pretty("bed_heater_active"),
        "bath_heater_active": this.get_pretty("bath_heater_active")
      },
      "design_temp": {
        "heating_system": {
          "rad_supply_temp_design": this.get_pretty("rad_supply_temp_design"),
          "floor_supply_temp_design": this.get_pretty("floor_supply_temp_design"),
          "rad1_temp_drop_design": this.get_pretty("rad_temp_drop_design"),
          "rad2_temp_drop_design": this.get_pretty("rad_temp_drop_design"),
          "floor_temp_drop_design": this.get_pretty("floor_temp_drop_design")
        }
      },
      "system_balancing": this.get_pretty("system_balancing"),
      "pipe_connection": {
        "heating_connection_pipes": this.get_pretty("heating_connection_pipes"),
        "heating_circulation_pump": this.get_pretty("heating_circulation_pump"),
        "bypass_rel_flow_setpoint": this.get_pretty("bypass_rel_flow_setpoint")
      },
      "domestic_hot_water": {
        "dhw_consump": this.get_pretty("dhw_consump"),
        "temp": this.get_pretty("temp"),
        "heater_size": this.get_pretty("heater_size")
      },
      "heat_supply": {
        "gas_size": this.get_pretty("gas_size"),
        "gas_min_rel": this.get_pretty("gas_min_rel"),
        "pillet_size": this.get_pretty("pillet_size"),
        "pillet_min_rel": this.get_pretty("pillet_min_rel"),
        "hp_cover_temp_design": this.get_pretty("hp_cover_temp_design"),
        "hp_dhw_power_design_part": this.get_pretty("hp_dhw_power_design_part"),
        "hp_min_rel": this.get_pretty("hp_min_rel"),
        "dh_supply_temp_design": this.get_pretty("dh_supply_temp_design"),
        "dh_exchanger_temp_drop_design": this.get_pretty("dh_exchanger_temp_drop_design")
      },
      "heating_curve": {
        "increase_rel": this.get_pretty("increase_rel"),
        "parallel_adjust_rel": this.get_pretty("parallel_adjust_rel"),
        "setback_rel": this.get_pretty("setback_rel"),
        "boost_rel": this.get_pretty("boost_rel")
      }
    }
    this.bake_local("vvs", vvsData);
    this.bake_local("vvs_pretty", vvs_data_pretty);
    // console.log(vvsData);
    // var heating_type = (this.read_local("house") != null) ? this.read_local("house") : "Not available";
    // console.log(heating_type);

  }


  get_pretty(id) {
    return document.getElementById(id).options[document.getElementById(id).selectedIndex].text
  }


  setData(vvsData) {
    document.getElementById("living_heater_rel_power").value = parseFloat(vvsData.room_heating.living_heater_rel_power);
    document.getElementById("bed_heater_rel_power").value = parseFloat(vvsData.room_heating.bed_heater_rel_power);
    document.getElementById("bath_heater_rel_power").value = parseFloat(vvsData.room_heating.bath_heater_rel_power);
    document.getElementById("living_setback").value = (vvsData.room_heating.living_setback);
    document.getElementById("bed_setback").value = (vvsData.room_heating.bed_setback);
    document.getElementById("bath_setback").value = (vvsData.room_heating.bath_setback);
    document.getElementById("living_heater_active").value = vvsData.room_heating.living_heater_active;
    document.getElementById("bed_heater_active").value = vvsData.room_heating.bed_heater_active;
    document.getElementById("bath_heater_active").value = vvsData.room_heating.bath_heater_active;

    document.getElementById("rad_supply_temp_design").value = parseFloat(vvsData.design_temp.heating_system.rad_supply_temp_design);
    document.getElementById("floor_supply_temp_design").value = parseFloat(vvsData.design_temp.heating_system.floor_supply_temp_design);
    document.getElementById("rad_temp_drop_design").value = parseFloat(vvsData.design_temp.heating_system.rad1_temp_drop_design);
    document.getElementById("rad_temp_drop_design").value = parseFloat(vvsData.design_temp.heating_system.rad2_temp_drop_design);
    document.getElementById("floor_temp_drop_design").value = parseFloat(vvsData.design_temp.heating_system.floor_temp_drop_design);

    document.getElementById("system_balancing").value = (vvsData.system_balancing);

    document.getElementById("heating_connection_pipes").value = vvsData.pipe_connection.heating_connection_pipes;
    document.getElementById("heating_circulation_pump").value = vvsData.pipe_connection.heating_circulation_pump;
    document.getElementById("bypass_rel_flow_setpoint").value = parseFloat(vvsData.pipe_connection.bypass_rel_flow_setpoint);
    
    document.getElementById("dhw_consump").value = vvsData.domestic_hot_water.dhw_consump;
    document.getElementById("temp").value = parseFloat(vvsData.domestic_hot_water.temp);
    document.getElementById("heater_size").value = parseFloat(vvsData.domestic_hot_water.heater_size);


    document.getElementById("gas_size").value = parseFloat(vvsData.heat_supply.gas_size);
    document.getElementById("gas_min_rel").value = parseFloat(vvsData.heat_supply.gas_min_rel);
    document.getElementById("pillet_size").value = parseFloat(vvsData.heat_supply.pillet_size);
    document.getElementById("pillet_min_rel").value = parseFloat(vvsData.heat_supply.pillet_min_rel);
    document.getElementById("hp_cover_temp_design").value = parseFloat(vvsData.heat_supply.hp_cover_temp_design);
    document.getElementById("hp_dhw_power_design_part").value = parseFloat(vvsData.heat_supply.hp_dhw_power_design_part);
    document.getElementById("hp_min_rel").value = parseFloat(vvsData.heat_supply.hp_min_rel);
    document.getElementById("dh_supply_temp_design").value = parseFloat(vvsData.heat_supply.dh_supply_temp_design);
    document.getElementById("dh_exchanger_temp_drop_design").value = vvsData.heat_supply.dh_exchanger_temp_drop_design;

    document.getElementById("increase_rel").value = parseFloat(vvsData.heating_curve.increase_rel);
    document.getElementById("parallel_adjust_rel").value = parseFloat(vvsData.heating_curve.parallel_adjust_rel);
    document.getElementById("setback_rel").value = parseFloat(vvsData.heating_curve.setback_rel);
    document.getElementById("boost_rel").value = parseFloat(vvsData.heating_curve.boost_rel);
  }


  bake_local(name, value) {
    window.localStorage.setItem(name, JSON.stringify(value));
  }

  read_local(name) {
    var result = window.localStorage.getItem(name);
    result && (result = JSON.parse(result));
    return result;
  }

  reload_images(vvsData) {
    // console.log("reloading images");
    this.collectData();

    let collectedData = {};
    collectedData.house = this.read_local("house");
    collectedData.vvs = this.read_local("vvs");

    // console.log(collectedData);
    let temp_curve_data_url = "/api/get_heat_curve_data?data_dict=" + JSON.stringify(collectedData);

    let temp_curve_data_uri = encodeURI("/api/get_heat_curve_data?data_dict=" + JSON.stringify(collectedData));
    var request = new XMLHttpRequest();

    let then = this;

    request.open('POST', temp_curve_data_uri, true);
    request.setRequestHeader("Content-type", "message");
    request.onload = function(data) {
      if (request.readyState === 4) {
        if (request.status === 200) {

            let temp_curve = JSON.parse(request.responseText);

            console.log("Here is the temp curve data");
            console.log(temp_curve);

            let heat_curve_url = "https://veff.sbi.dk/api/create_heat_curve_graph?data_dict=" + JSON.stringify(temp_curve) + "&width=" + 700 + "&height=" + 400;

            document.getElementById("bar_curve_flat").src = encodeURI(heat_curve_url);

            

            then.bake_local('heat_curve_url', encodeURIComponent(heat_curve_url));

        } else {
          console.error(request.statusText);
        }
      }
    };
    request.onerror = function (e) {
      console.error(request);
    };

    request.send(null);

   }


  componentDidMount() {
    let vvsData = this.read_local("vvs");
    if (JSON.stringify(vvsData) == 'null') {
      this.collectData();
      vvsData = this.read_local("vvs");
    }
    this.setData(vvsData);
    document.getElementById("bar_curve_flat").src = "/static/loading_image.png";
    this.reload_images(vvsData);
  }

  render() {
    const customButtonInlineStyle = { marginTop: "Auto", width: "90%" };

    var heating_type = (this.read_local("house") != null) ? this.read_local("house").heating_type : "Gas";
    var heating_type_pretty = (this.read_local("house_pretty") != null) ? this.read_local("house_pretty").heating_type : "Gaskedel";
    // console.log(heating_type);

    var heating_giver_1 = (this.read_local("house") != null) ? this.read_local("house").living_heater : "Radiatorer2Streng";
    var heating_giver_2 = (this.read_local("house") != null) ? this.read_local("house").bed_heater : "LetGulvvarme";
    var heating_giver_3 = (this.read_local("house") != null) ? this.read_local("house").bath_heater : "Radiatorer1Streng";

    var show_r1 = (heating_giver_1 === "Radiatorer1Streng" || heating_giver_2 === "Radiatorer1Streng" || heating_giver_3 === "Radiatorer1Streng")
    var show_r2 = (heating_giver_1 === "Radiatorer2Streng" || heating_giver_2 === "Radiatorer2Streng" || heating_giver_3 === "Radiatorer2Streng")
    var show_floor = (heating_giver_1 === "LetGulvvarme" || heating_giver_2 === "LetGulvvarme" || heating_giver_3 === "LetGulvvarme") || (heating_giver_1 === "TungGulvvarme" || heating_giver_2 === "TungGulvvarme" || heating_giver_3 === "TungGulvvarme")

    let temp_curve = {
      'operation': {
        'ref_min_adjust': 22.9,
        'ref_max_adjust': -12.0,
        'max': 86.0,
      },
      'setback': {
        'ref_min_adjust': 14.2,
        'ref_max_adjust': -12.0,
        'max': 69.5,
      },
      'boost': {
        'ref_min_adjust': 37.5,
        'ref_max_adjust': 0.4,
        'max': 90.0,
      },
      'dim': {
        'outer_temp': -12,
        'inner_temp': 75
      },
      'temp_curve_basis': 20.0,
    };


    let window_type = this.read_local("house").house_windows;
    let insulation =  this.read_local("house").house_insulation;

    let living_dim = ""
    let bed_dim = ""
    let bath_dim = ""

    let bsim_living_area = 71.0
    let bsim_bed_area = 64.0
    let bsim_bath_area = 9.0

    let typed_living_area = this.read_local("house").living_input_area;
    let typed_bed_area = this.read_local("house").bed_input_area;
    let typed_bath_area = this.read_local("house").bath_input_area;

   // __          __              _             
   // \ \        / /             (_)            
   //  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _ 
   //   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` |
   //    \  /\  / (_| | |  | | | | | | | | (_| |
   //     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, |
   //                                      __/ |
   //                                     |___/ 

   // This needs to be corrected if the model is changed at any time

    if (window_type === "AlmTermoruder" && insulation === "Lav") {
      living_dim += (4209 / bsim_living_area * typed_living_area).toFixed(0) + " W"
      bed_dim += (3316 / bsim_bed_area * typed_bed_area).toFixed(0) + " W"
      bath_dim += (494 / bsim_bath_area * typed_bath_area).toFixed(0) + " W"
    }
    if (window_type === "AlmTermoruder" && insulation === "Middel") {
      living_dim += (3593 / bsim_living_area * typed_living_area).toFixed(0) + " W"
      bed_dim += (2719 / bsim_bed_area * typed_bed_area).toFixed(0) + " W"
      bath_dim += (417 / bsim_bath_area * typed_bath_area).toFixed(0) + " W"
    }
    if (window_type === "AlmTermoruder" && insulation === "Hoej") {
      living_dim += (2878 / bsim_living_area * typed_living_area).toFixed(0) + " W"
      bed_dim += (2025 / bsim_bed_area * typed_bed_area).toFixed(0) + " W"
      bath_dim += (328 / bsim_bath_area * typed_bath_area).toFixed(0) + " W"
    }
    if (window_type === "Energiruder" && insulation === "Lav") {
      living_dim += (3747 / bsim_living_area * typed_living_area).toFixed(0) + " W"
      bed_dim += (3136 / bsim_bed_area * typed_bed_area).toFixed(0) + " W"
      bath_dim += (450 / bsim_bath_area * typed_bath_area).toFixed(0) + " W"
    }
    if (window_type === "Energiruder" && insulation === "Middel") {
      living_dim += (3126 / bsim_living_area * typed_living_area).toFixed(0) + " W"
      bed_dim += (2535 / bsim_bed_area * typed_bed_area).toFixed(0) + " W"
      bath_dim += (372 / bsim_bath_area * typed_bath_area).toFixed(0) + " W"
    }
    if (window_type === "Energiruder" && insulation === "Hoej") {
      living_dim += (2417 / bsim_living_area * typed_living_area).toFixed(0) + " W"
      bed_dim += (1846 / bsim_bed_area * typed_bed_area).toFixed(0) + " W"
      bath_dim += (283 / bsim_bath_area * typed_bath_area).toFixed(0) + " W"
    }

    return (
      <div className="top_level">
        <Header site_number="2" />
        <div className="content">

          <Section width="90%">
            <div className={style.basis}>
            <div style={{ width: "47%", minWidth: "154px" }}>
            <h2 className={style.h2}>Rumopvarmning</h2>
              <div style={{ width: "100%"}}>
                <h3 className={style.h3}>Varmegivereffekt<HelpButton help_id="heater_rel_power"></HelpButton></h3>
                <CustomDropdown2 title="Stue og køkken" id="living_heater_rel_power" width="100%">
                  <option value={0.7}>70 %</option>
                  <option value={0.8}>80 %</option>
                  <option value={0.9}>90 %</option>
                  <option selected value={1.0}>100 %</option>
                  <option value={1.1}>110 %</option>
                  <option value={1.2}>120 %</option>
                  <option value={1.3}>130 %</option>
                </CustomDropdown2>
                <p class={style.psmall} style={{marginLeft: "2vw", display: "inline-flex"}}>Dimensionerende varmetab: {living_dim}</p>
                <CustomDropdown2 title="Værelser" id="bed_heater_rel_power" width="100%">
                <option value={0.7}>70 %</option>
                  <option value={0.8}>80 %</option>
                  <option value={0.9}>90 %</option>
                  <option selected value={1.0}>100 %</option>
                  <option value={1.1}>110 %</option>
                  <option value={1.2}>120 %</option>
                  <option value={1.3}>130 %</option>
                </CustomDropdown2>
                <p class={style.psmall} style={{marginLeft: "2vw", display: "inline-flex"}}>Dimensionerende varmetab: {bed_dim}</p>
                <CustomDropdown2 title="Baderum" id="bath_heater_rel_power" width="100%">
                <option value={0.7}>70 %</option>
                  <option value={0.8}>80 %</option>
                  <option value={0.9}>90 %</option>
                  <option selected value={1.0}>100 %</option>
                  <option value={1.1}>110 %</option>
                  <option value={1.2}>120 %</option>
                  <option value={1.3}>130 %</option>
                </CustomDropdown2>
                <p class={style.psmall} style={{marginLeft: "2vw", display: "inline-flex"}}>Dimensionerende varmetab: {bath_dim}</p>
              </div>
              <div style={{ width: "100%"}}>
                <h3 className={style.h3}>Natsænkning<HelpButton help_id="setback"></HelpButton></h3>
                <CustomDropdown2 title="Stue og køkken" id="living_setback" width="100%">
                  <option value={true}>Ja</option>
                  <option selected value={false}>Nej</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Værelser" id="bed_setback" width="100%">
                  <option value={true}>Ja</option>
                  <option selected value={false}>Nej</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Baderum" id="bath_setback" width="100%">
                  <option value={true}>Ja</option>
                  <option selected value={false}>Nej</option>
                </CustomDropdown2>
              </div>
              <div style={{ width: "100%"}}>
                <h3 className={style.h3}>Åbne varmegivere<HelpButton help_id="heater_active"></HelpButton></h3>
                <CustomDropdown2 title="Stue og køkken" id="living_heater_active" width="100%">
                  <option selected value="Alle">Alle</option>
                  <option value="Kvart">3/4-del</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Værelser" id="bed_heater_active" width="100%">
                  <option selected value="Alle">Alle</option>
                  <option value="Kvart">3/4-del</option>
                </CustomDropdown2>
                <CustomDropdown2 title="Baderum" id="bath_heater_active" width="100%">
                  <option selected value="Alle">Alle</option>
                  <option value="Kvart">3/4-del</option>
                </CustomDropdown2>
              </div>
              <h2 className={style.h2}>Brugsvands&shy;opvarmning</h2>
                <div style={{ width: "100%", minWidth: "154px" }}>
                  <CustomDropdown2 title="Varmtvandsforbrug" id="dhw_consump" width="100%" label="outer">
                    <option value="Middel" selected>Middel</option>
                    <option value="Stort">Stort</option>
                  </CustomDropdown2>
                  <CustomDropdown2 title="Varmtvandstemperatur" id="temp" label="outer" width="100%">
                    <option value={55} selected>55 °C</option>
                    <option value={60}>60 °C</option>
                    <option value={65}>65 °C</option>
                  </CustomDropdown2>
                  <CustomDropdown2 title="Hedeflade" id="heater_size" label="outer" width="100%">
                    <option value={0.7}>0,70 m&sup2;</option>
                    <option value={1.0}>1,00 m&sup2;</option>
                    <option value={1.5}>1,50 m&sup2;</option>
                  </CustomDropdown2>
                </div>
            </div>
            <div style={{ width: "47%", minWidth: "154px" }}>
            <h2 className={style.h2}>Dimensionerende temperaturer</h2>
              <div style={{ width: "100%"}}>
              <h3 className={style.h3}>Fremløb<HelpButton help_id="forward"></HelpButton></h3>
                  <CustomDropdown2 title="Radiatorer" visible={show_r1 || show_r2} id="rad_supply_temp_design" width="100%" onChange={() => this.reload_images()} >
                    <option value={55}>55 °C</option>
                    <option value={65}>65 °C</option>
                    <option value={75} selected>75 °C</option>
                    <option value={90}>90 °C</option>
                  </CustomDropdown2>
                  <CustomDropdown2 title="Gulvvarme" visible={show_floor} id="floor_supply_temp_design" width="100%" onChange={() => this.reload_images()}>
                    <option value={30}>30 °C</option>
                    <option value={35}>35 °C</option>
                    <option value={40} selected>40 °C</option>
                    <option value={45}>45 °C</option>
                  </CustomDropdown2>
              </div>
              <div style={{ width: "100%"}}>
              <h3 className={style.h3}>Afkøling<HelpButton help_id="backward"></HelpButton></h3>
                  <CustomDropdown2 visible={show_r1 || show_r2} title="Radiatorer" id="rad_temp_drop_design" width="100%">
                    <option value={10} selected>10 °C</option>
                    <option value={20} >20 °C</option>
                    <option value={30} >30 °C</option>
                  </CustomDropdown2>
                  <CustomDropdown2 visible={show_floor} title="Gulvvarme" id="floor_temp_drop_design" width="100%">
                    <option value={5} >5 °C</option>
                    <option value={10} selected>10 °C</option>
                  </CustomDropdown2>
              </div>
              <h2 className={style.h2}>Tilslutninger</h2>
                <div style={{ width: "100%", minWidth: "154px" }}>
                  <CustomDropdown2 title="Tilslutningsrør" id="heating_connection_pipes" label="outer" width="100%">
                    <option value="MegetSmaa">Meget små</option>
                    <option value="Smaa">Små</option>
                    <option value="Tilstraekkelig" selected>Tilstrækkelig</option>
                  </CustomDropdown2>
                  <CustomDropdown2 title="Cirkulationspumpe" id="heating_circulation_pump" label="outer" width="100%">
                    <option value="MegetLille">Meget lille</option>
                    <option value="Lille">Lille</option>
                    <option value="Tilstraekkelig" selected>Tilstrækkelig</option>
                    <option value="Stor">Stor</option>
                    <option value="MegetStor">Meget Stor</option>
                  </CustomDropdown2>
                  <CustomDropdown2 title="Omløb" id="bypass_rel_flow_setpoint" label="outer" width="100%">
                    <option value={0.0} selected>0 %</option>
                    <option value={0.4}>40 %</option>
                    <option value={0.6}>60 %</option>
                    <option value={0.8}>80 %</option>
                    <option value={1.0}>100 %</option>
                  </CustomDropdown2>
                </div>
                <h2 className={style.h2}>Indregulering</h2>
                <div style={{ width: "100%", minWidth: "154px" }}>
                  <CustomDropdown2 title="Indreguleret" id="system_balancing" label="outer" width="100%">
                    <option value={false}>Nej</option>
                    <option value={true} selected>Ja</option>
                  </CustomDropdown2>
                </div>
            </div>
            </div>
          </Section>
          <Section width="90%">
          {/* <div style={{display: "flex",  flexDirection: "column",alignItems: "center"}}> */}
            <h2 className={style.h2}>Fremløbstemperatur</h2>
            <img id="bar_curve_flat" alt="Varmekurve" className={style.image}></img>
            <div className={style.basis}>
              <CustomDropdown2 title="Hævning" id="increase_rel" onChange={() => this.reload_images()} label="outer" width="47%">
                <option value={-1.0}>-100 %</option>
                <option value={-0.4}> -40 %</option>
                <option value={-0.3}>-30 %</option>
                <option value={-0.2}>-20 %</option>
                <option value={-0.1}> -10 %</option>
                <option value={0.0} selected>0 %</option>
                <option value={0.1}> 10 %</option>
                <option value={0.2}>20 %</option>
                <option value={0.3}>30 %</option>
                <option value={0.4}>40 %</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Natsænkning" id="setback_rel" onChange={() => this.reload_images()} label="outer" width="47%">
              <option value={0.0} selected>0 %</option>
                <option value={0.1}>10 %</option>
                <option value={0.2}>20 %</option>
                <option value={0.3}>30 %</option>
                <option value={0.4}>40 %</option>
                <option value={0.5}>50 %</option>
                <option value={0.6}>60 %</option>
                <option value={0.7}>70 %</option>
                <option value={0.8}>80 %</option>
                <option value={1.0}>100 %</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Parallelforskydning" id="parallel_adjust_rel" onChange={() => this.reload_images()} label="outer" width="47%">
                <option value={-0.4}>-40 %</option>
                <option value={-0.3}>-30 %</option>
                <option value={-0.2}>-20 %</option>
                <option value={-0.1}>-10 %</option>
                <option value={0.0} selected>0 %</option>
                <option value={0.1}>10 %</option>
                <option value={0.2}>20 %</option>
                <option value={0.3}>30 %</option>
                <option value={0.4}>40 %</option>
                <option value={0.5}>50 %</option>
                <option value={0.6}>60 %</option>
                <option value={0.7}>70 %</option>
                <option value={0.8}>80 %</option>
                <option value={1.0}>100 %</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Morgenforcering" id="boost_rel" onChange={() => this.reload_images()} label="outer" width="47%">
                <option value={0.0} selected>0 %</option>
                <option value={0.1}>10 %</option>
                <option value={0.2}>20 %</option>
                <option value={0.3}>30 %</option>
                <option value={0.4}>40 %</option>
                <option value={0.5}>50 %</option>
                <option value={0.6}>60 %</option>
                <option value={0.7}>70 %</option>
                <option value={0.8}>80 %</option>
                <option value={1.0}>100 %</option>
              </CustomDropdown2>
            </div>
            <h2 className={style.h2}>{heating_type_pretty}</h2>
            <div className={style.basis}>
              {/* Gaskedel */}
              <CustomDropdown2 title="Kedelstørrelse" id="gas_size" visible={heating_type === "Gas"} label="outer" width="47%">
                <option value={12000.0}>12 kW</option>
                <option value={18000.0} selected>18 kW</option>
                <option value={24000.0}>24 kW</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Modulationsgrænse" id="gas_min_rel" visible={heating_type === "Gas"} label="outer" width="47%">
                <option value={0.3}>30 %</option>
                <option value={0.5} selected>50 %</option>
                <option value={0.7}>70 %</option>
                <option value={1.0}>100 %</option>
              </CustomDropdown2>
              {/* Pillefyr */}
              <CustomDropdown2 title="Kedelstørrelse" id="pillet_size" visible={heating_type === "Piller"} label="outer" width="47%">
                <option value={12000.0}>12 kW</option>
                <option value={18000.0} selected>18 kW</option>
                <option value={24000.0}>24 kW</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Modulationsgrænse" id="pillet_min_rel" visible={heating_type === "Piller"} label="outer" width="47%">
                <option value={0.3}>30 %</option>
                <option value={0.5} selected>50 %</option>
                <option value={0.7}>70 %</option>
                <option value={1.0}>100 %</option>
              </CustomDropdown2>
              {/* Varmepumpe */}
              <CustomDropdown2 title="Dækningstemperatur" id="hp_cover_temp_design" visible={heating_type === "Varmepumpe"} label="outer" width="47%">
                <option value={-2.0}>-2 °C</option>
                <option value={-7.0} selected>-7 °C</option>
                <option value={-12.0}>-12 °C</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Modulationsgrænse" id="hp_min_rel" visible={heating_type === "Varmepumpe"} label="outer" width="47%">
                <option value={0.3}>30 %</option>
                <option value={0.5} selected>50 %</option>
                <option value={0.7}>70 %</option>
                <option value={1.0}>100 %</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Større til varmt brugsvand" id="hp_dhw_power_design_part" visible={heating_type === "Varmepumpe"} label="outer" width="47%">
                <option value={0.0} selected>0 %</option>
                <option value={0.1}>10 %</option>
                <option value={0.2}>20 %</option>
                <option value={0.3}>30 %</option>
                <option value={0.4}>40 %</option>
                <option value={0.5}>50 %</option>
              </CustomDropdown2>
              {/* Fjernvarmeveksler */}
              <CustomDropdown2 title="Fjernvarme maks. fremløbstemp." id="dh_supply_temp_design" visible={heating_type === "Fjernvarme"} label="outer" width="47%">
                <option value={95}>95 °C</option>
                <option value={85} selected>85 °C</option>
                <option value={75}>75 °C</option>
              </CustomDropdown2>
              <CustomDropdown2 title="Vekslerstørrelse" id="dh_exchanger_temp_drop_design" visible={heating_type === "Fjernvarme"} label="outer" width="47%">
                <option value="Almindelig" selected>Almindelig</option>
                <option value="Stor">Stor</option>
              </CustomDropdown2>
            </div>
            <div style={{ display: "flex" }}>
              <CustomButton class="button" text="Forrige" location="/BuildingInputPage" onClick={() => this.collectData()} />
              <CustomButton class="button" text="Næste" location="/ResultPage" onClick={() => this.collectData()} />
            </div>
            {/* </div> */}
          </Section>
        </div>
        <Footer />
      </div>
    );
  }


}