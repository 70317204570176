import React from 'react';
import CustomButton from "../components/CustomButton"
import Header from '../components/Header';
import Footer from '../components/Footer';
import Section from '../components/Section';
import "../styling/HomePage.css";
import fjern0 from "../images/fjern0.png";
import fjern1 from "../images/fjern1.png";
import fjern2 from "../images/fjern2.png";
import fjern3 from "../images/fjern3.png";
import fjern4 from "../images/fjern4.png";
import pumpe0 from "../images/pumpe0.png";
import pumpe1 from "../images/pumpe1.png";
import pumpe2 from "../images/pumpe2.png";
import pumpe3 from "../images/pumpe3.png";
import pumpe4 from "../images/pumpe4.png";
import kedel0 from "../images/kedel0.png";
import kedel1 from "../images/kedel1.png";
import kedel2 from "../images/kedel2.png";
import kedel3 from "../images/kedel3.png";
import kedel4 from "../images/kedel4.png";


import style from "../stylemodules/BuildingPage.module.css"

export default class ResultPage extends React.Component {

  state = {
    from_backend: {
      "gb_eff": 0.01,
      "gb_fuel_gross": 0.01,

      "pb_eff": 0.01,
      "pb_fuel_gross": 0.01,

      "hp_cop": 0,
      "hp_gross": 0.01,
      "hp_resist": 0.01,

      "eh_electricity": 0.01,
      "eh_water": 0.01,
      "eh_cooling": 0.01,


      "room_heating": 0.01,
      "hot_water": 0.01,

      "living_hours": 0,
      "living_hours_harsh": 0,
      "living_max_temp": 0,
      "living_min_temp": 0,
      "living_indoor_temp": 0,
      "living_time_percent": 0,
      "living_coldest_day": 0,
      "living_coldest_month": 0,
      "living_coldest_hour": 1,

      "bed_hours": 0,
      "bed_hours_harsh": 0,
      "bed_max_temp": 0,
      "bed_min_temp": 0,
      "bed_indoor_temp": 0,
      "bed_time_percent": 0,
      "bed_coldest_day": 0,
      "bed_coldest_month": 0,
      "bed_coldest_hour": 1,

      "bath_hours": 0,
      "bath_hours_harsh": 0,
      "bath_max_temp": 0,
      "bath_min_temp": 0,
      "bath_indoor_temp": 0,
      "bath_time_percent": 0,
      "bath_coldest_day": 0,
      "bath_coldest_month": 0,
      "bath_coldest_hour": 1
    },
    loading: true,
    job_id: -1,
    client_token: "",
    loop: null,
    show_in_two: false
  }



  bake_local(name, value) {
    window.localStorage.setItem(name, JSON.stringify(value));
  }

  read_local(name) {
    var result = window.localStorage.getItem(name)
    result && (result = JSON.parse(result));
    return result;
  }

  resize() {
    let show_in_two = (window.innerWidth <= 1100);
    if (show_in_two !== this.state.show_in_two) {
        this.setState({show_in_two: show_in_two});
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();



    let collectedData = {};
    collectedData.house = this.read_local("house");
    collectedData.vvs = this.read_local("vvs");

    let result_data_url = encodeURI("/api/submit_job?data_dict=" + JSON.stringify(collectedData));
    var request = new XMLHttpRequest();
    request.open('POST', result_data_url, true);
    // console.log("Async call started")
    let that = this
    request.timeout = 10000;

    request.ontimeout = function (e) {
      alert("Noget gik galt, prøv igen!")
      window.location.href = "/VVSDataPage"
    };

    request.onload = function (data) {
      if (request.readyState === 4) {
        if (request.status === 200) {

          // console.log(request)
          let from_backend_data = JSON.parse(request.responseText);

          // that.bake_local("results", from_backend_data)

          // from_backend_data.room_heating = from_backend_data.room_heating / 1000000 // W to MW conversion
          // from_backend_data.hot_water = from_backend_data.hot_water / 1000000 // W to MW conversion

            if (from_backend_data.job_id) {
                console.log("Cache miss")
            } else {
                console.log("Cache hit")
                
                from_backend_data = from_backend_data.result
                that.bake_local("results", from_backend_data)
                
                // W to MW conversion
                from_backend_data.room_heating = from_backend_data.room_heating / 1000000 
                from_backend_data.hot_water = from_backend_data.hot_water / 1000000 

                that.setState({ from_backend: from_backend_data, loading: false})
                console.log("Done")

                return
            }


          let loop = setInterval(that.ask_for_status.bind(that), 1000)

          console.log("Job ID:")
          console.log(from_backend_data.job_id)
          console.log("Client token: " + from_backend_data.client_token)

          console.log(from_backend_data)
          that.setState({loop: loop, job_id: from_backend_data.job_id, client_token: from_backend_data.client_token})
          console.log(that.state.job_id)

          // that.setState({ from_backend: from_backend_data, loading: false, loop: loop, job_id: from_backend_data.job_id, client_token: from_backend_data.client_token})


        } else {
          document.location = "/ErrorPage?error_message=" + request.statusText;
        }
      }
    };
    request.onerror = function (e) {
      document.location = "/ErrorPage?error_message=" + request.statusText;
    };



    request.send(null);
  }

    // The results should be stored in local "results"
    process_results() {
        console.log("SVEND GYNGE")
    }


  get_final_result() {

    let collectedData = {};
    collectedData.house = this.read_local("house");
    collectedData.vvs = this.read_local("vvs");

    let result_data_url = encodeURI("/api/get_job_result?data_dict=" + JSON.stringify(collectedData) + "&job_id=" + this.state.job_id + "&client_token=" + JSON.stringify(this.state.client_token));
    var request = new XMLHttpRequest();
    request.open('POST', result_data_url, true);

    let then = this

    request.onload = function (data) {
      if (request.readyState === 4) {
        if (request.status === 200) {
          
          let from_backend_data = JSON.parse(request.responseText);
          from_backend_data = from_backend_data.result
          console.log(from_backend_data)
          then.bake_local("results", from_backend_data)

          from_backend_data.room_heating = from_backend_data.room_heating / 1000000 // W to MW conversion
          from_backend_data.hot_water = from_backend_data.hot_water / 1000000 // W to MW conversion
          
          then.setState({ from_backend: from_backend_data, loading: false})
          console.log("Done")

          
        } else {
          document.location = "/ErrorPage?error_message=" + request.statusText;
          console.error(request.statusText);
          setTimeout(function() {
            then.get_final_result();
          }, 1000)
        }
      }
    };
    request.onerror = function (e) {
      document.location = "/ErrorPage?error_message=" + request.statusText;
      console.error(request.statusText);
      setTimeout(function() {
        then.get_final_result();
      }, 1000)
    };

    

    request.send(null);

  }

  get_thousand(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  


  ask_for_status() {
    let result_data_url = encodeURI("/api/check_job_status?job_id=" + this.state.job_id + "&client_token=" + JSON.stringify(this.state.client_token));
    // let result_data_url = encodeURI("/api/get_job_result?data_dict=" + JSON.stringify(collectedData) + "&job_id=" + 56 + "&client_token=adawd");
    var request = new XMLHttpRequest();
    request.open('POST', result_data_url, true);

    let thus = this

    request.onload = function (data) {
      if (request.readyState === 4) {
        if (request.status === 200) {
          switch (request.responseText) {
            case "Success":
              clearInterval(thus.state.loop);
              thus.get_final_result()
              console.log("Results from bsim are in")
              console.log("Doing final calculations")
              break;
            case "New":
                console.log("New")
              break;
            case "Started":
                console.log("In progress")
              break;
            case "Failed":
                  alert("Noget gik galt. Genindlæs siden for at prøve igen!")
              break;
          }
          
          
          
        } else {
          document.location = "/ErrorPage?error_message=" + request.statusText;
        }
      }
    };
    request.onerror = function (e) {
      document.location = "/ErrorPage?error_message=" + request.statusText;
    };

    

    request.send(null);
  }

  get_pdf_data() {
    let collectedData = {};
    collectedData.name = this.read_local("name");
    collectedData.house = this.read_local("house_pretty");
    collectedData.vvs = this.read_local("vvs_pretty");


    const hoursNames = ["0-1", "1-2", "2-3", "3-4", "4-5", "5-6",
      "6-7", "7-8", "8-9", "9-10", "10-11", "11-12",
      "12-13", "13-14", "14-15", "15-16", "16-17", "17-18",
      "18-19", "19-20", "20-21", "21-22", "22-23", "23-0"
    ];

    let unformatted_results = (this.read_local("results") != undefined) ? this.read_local("results") : this.state.from_backend
    let formatted_results = {
      "gb_eff": unformatted_results.gb_eff.toFixed(1).toLocaleString('da-DK') + " %",
      "gb_fuel_gross": unformatted_results.gb_fuel_gross.toFixed(1).toLocaleString('da-DK') + " m³",

      "pb_eff": unformatted_results.pb_eff.toFixed(1).toLocaleString('da-DK') + " %",
      "pb_fuel_gross": unformatted_results.pb_fuel_gross.toFixed(1).toLocaleString('da-DK') + " kg-piller/år",

      "hp_cop": unformatted_results.hp_cop.toFixed(2).toLocaleString('da-DK'),
      "hp_gross": this.get_thousand((unformatted_results.hp_gross / 1000).toFixed(0).toLocaleString('da-DK')) + " kWh/år",
      "hp_resist": (unformatted_results.hp_resist / 1000).toFixed(0).toLocaleString('da-DK') + " kWh/år",

      "eh_electricity": (unformatted_results.eh_electricity / 1000000).toFixed(1).toLocaleString('da-DK') + " MWh/år", //wh to mwh
      "eh_water": unformatted_results.eh_water.toFixed(1).toLocaleString('da-DK') + " m³/år",
      "eh_cooling": unformatted_results.eh_cooling.toFixed(1).toLocaleString('da-DK') + " °C/år",
      
      
      "room_heating": (unformatted_results.room_heating / 1000000).toFixed(2).toLocaleString('da-DK') + " MWh/år",
      "hot_water": (unformatted_results.hot_water / 1000000).toFixed(2).toLocaleString('da-DK') + " MWh/år",

      "living_hours": unformatted_results.living_hours.toLocaleString('da-DK'),
      "living_max_temp": unformatted_results.living_max_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "living_min_temp": unformatted_results.living_min_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "living_indoor_temp": unformatted_results.living_indoor_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "living_time_percent": unformatted_results.living_time_percent + " awd",
      "living_coldest_day": unformatted_results.living_coldest_day ,
      "living_coldest_month": unformatted_results.living_coldest_month,
      "living_coldest_hour": hoursNames[unformatted_results.living_coldest_hour-1],

      "bed_hours": unformatted_results.bed_hours.toLocaleString('da-DK'),
      "bed_max_temp": unformatted_results.bed_max_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "bed_min_temp": unformatted_results.bed_min_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "bed_indoor_temp": unformatted_results.bed_indoor_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "bed_time_percent": unformatted_results.bed_time_percent + " awd",
      "bed_coldest_day": unformatted_results.bed_coldest_day,
      "bed_coldest_month": unformatted_results.bed_coldest_month,
      "bed_coldest_hour": hoursNames[unformatted_results.bed_coldest_hour-1],

      "bath_hours": unformatted_results.bath_hours.toLocaleString('da-DK'),
      "bath_max_temp": unformatted_results.bath_max_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "bath_min_temp": unformatted_results.bath_min_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "bath_indoor_temp": unformatted_results.bath_indoor_temp.toFixed(1).toLocaleString('da-DK') + " °C",
      "bath_time_percent": unformatted_results.bath_time_percent + " awd",
      "bath_coldest_day": unformatted_results.bath_coldest_day,
      "bath_coldest_month": unformatted_results.bath_coldest_month,
      "bath_coldest_hour":  hoursNames[unformatted_results.bath_coldest_hour-1]
    }

    //console.log(formatted_results)

    let type = collectedData.house.heating_type
    let diff = {}

    switch (type) {
      case "Fjernvarme":
          console.log("Fjernvarme")
        diff.opres1 = formatted_results.eh_electricity
        diff.optext1 = "Energi"
        diff.opres2 = formatted_results.eh_water
        diff.optext2 = "Vand"
        diff.opres3 = formatted_results.eh_cooling
        diff.optext3 = "Afkøling"
        diff.vares1 = collectedData.vvs.heat_supply.dh_supply_temp_design
        diff.vares2 = collectedData.vvs.heat_supply.dh_exchanger_temp_drop_design
        diff.vares3 = ""
        diff.vatext1 = "Fjernvarme maks. fremløbstemp."
        diff.vatext2 = "Vekslerstørrelse"
        diff.vatext3 = ""
        diff.vaheader = type
          break
      case "Varmepumpe":
          console.log("Varmepumpe")
        diff.opres1 = formatted_results.hp_cop
        diff.optext1 = "Varmepumpe COP"
        diff.opres2 = formatted_results.hp_gross
        diff.optext2 = "Elforbrug"
        diff.opres3 = formatted_results.hp_resist
        diff.optext3 = "Elpatron"
        diff.vares1 = collectedData.vvs.heat_supply.hp_cover_temp_design
        diff.vares2 = collectedData.vvs.heat_supply.hp_min_rel
        diff.vares3 = collectedData.vvs.heat_supply.hp_dhw_power_design_part
        diff.vatext1 = "Dækningstemperatur"
        diff.vatext2 = "Modulationsgrænse"
        diff.vatext3 = "Større til varmt brugsvand"
        diff.vaheader = type
        break
      case "Gaskedel":
        console.log("Gas")
        diff.opres1 = formatted_results.gb_eff
        diff.optext1 = "Kedelvirkningsgrad"
        diff.opres2 = formatted_results.gb_fuel_gross
        diff.optext2 = "Gasforbrug"
        diff.opres3 = ""
        diff.optext3 = ""
        diff.vares1 = collectedData.vvs.heat_supply.gas_size
        diff.vares2 = collectedData.vvs.heat_supply.gas_min_rel
        diff.vares3 = ""
        diff.vatext1 = "Kedelstørrelse"
        diff.vatext2 = "Modulationsgrænse"
        diff.vatext3 = ""
        diff.vaheader = type
        break
      case "Pillefyr":
          console.log("Piller")
        diff.opres1 = formatted_results.pb_eff
        diff.optext1 = "Kedelvirkningsgrad"
        diff.opres2 = formatted_results.pb_fuel_gross
        diff.optext2 = "Pilleforbrug"
        diff.opres3 = ""
        diff.optext3 = ""
        diff.vares1 = collectedData.vvs.heat_supply.pillet_size
        diff.vares2 = collectedData.vvs.heat_supply.pillet_min_rel
        diff.vares3 = ""
        diff.vatext1 = "Kedelstørrelse"
        diff.vatext2 = "Modulationsgrænse"
        diff.vatext3 = ""
        diff.vaheader = type
        break
        default:
          console.log(type + " does not exist")
    }
    


    
    collectedData.results = formatted_results
    collectedData.diff = diff
    collectedData.heat_curve_url = this.read_local('heat_curve_url');
    collectedData.bar_chart_url = this.read_local('bar_chart_url');
    collectedData.varme = this.read_local('varme');

    

    console.log(collectedData);
    let result_data_url = encodeURI("/download_pdf?data_dict=" + JSON.stringify(collectedData));

      const downloadLink = document.createElement("a");
      downloadLink.href = result_data_url;
      downloadLink.click();
  
    //console.log(JSON.stringify(collectedData));
  }

  get_debug() {
    let collectedData = {};
    collectedData.house = this.read_local("house");
    collectedData.vvs = this.read_local("vvs");

    let hour_start = Math.min(document.getElementById("debug_hour_end").value, document.getElementById("debug_hour_start").value)
    let hour_end = Math.max(document.getElementById("debug_hour_end").value, document.getElementById("debug_hour_start").value)

    if (hour_end == hour_start) hour_end++

    let debug_url = encodeURI("/api/get_job_result_debug?data_dict=" + JSON.stringify(collectedData) + "&job_id=" + this.state.job_id + "&hour_start=" + hour_start + "&hour_end=" + hour_end);
    window.open(debug_url, '_blank')
  }


  render() {

    const { from_backend, loading } = this.state;

    let customloadingStyle = {}

    if (loading) {
      customloadingStyle = { display: "flex" }
    } else {
      customloadingStyle = { display: "none" }
    };

    let collectedData = {}
    collectedData.house = this.read_local("house")
    collectedData.house_pretty = this.read_local("house_pretty")
    collectedData.vvs = this.read_local("vvs")
    collectedData.vvs_pretty = this.read_local("vvs_pretty")

    let image = fjern0;

    let gas_targets = [94.0, 96.0, 98.0, 101.0]
    let fjern_targets = [25.0, 30.0, 35.0, 40.0]
    let pillet_targets = [75.0, 78.0, 81.0, 84.0]
    let pump_targets = [2.3, 2.7, 3.1, 3.5]

    // [1, 2, 3, 4]
    // very bad (1) bad (2) ok (3) good (4) very good


    switch (collectedData.house.heating_type) {
      case "Gas":
        if (from_backend.gas_boiler_eff < gas_targets[0])
          image = kedel0
        else if (from_backend.gas_boiler_eff < gas_targets[1])
          image = kedel1
        else if (from_backend.gas_boiler_eff < gas_targets[2])
          image = kedel2
        else if (from_backend.gas_boiler_eff < gas_targets[3])
          image = kedel3
        else
          image = kedel4
        break
      case "Piller":
        if (from_backend.pillet_boiler_eff < pillet_targets[0])
          image = kedel0
        else if (from_backend.pillet_boiler_eff < pillet_targets[1])
          image = kedel1
        else if (from_backend.pillet_boiler_eff < pillet_targets[2])
          image = kedel2
        else if (from_backend.pillet_boiler_eff < pillet_targets[3])
          image = kedel3
        else
          image = kedel4
        break
      case "Varmepumpe":
        if (from_backend.hp_cop < pump_targets[0])
          image = pumpe0
        else if (from_backend.hp_cop < pump_targets[1])
          image = pumpe1
        else if (from_backend.hp_cop < pump_targets[2])
          image = pumpe2
        else if (from_backend.hp_cop < pump_targets[3])
          image = pumpe3
        else
          image = pumpe4
        break
      case "Fjernvarme":
        if (from_backend.eh_cooling < fjern_targets[0]) {
          image = fjern0
        }
        else if (from_backend.eh_cooling < fjern_targets[1]) {
          image = fjern1
        }
        else if (from_backend.eh_cooling < fjern_targets[2]) {
          image = fjern2
        }
        else if (from_backend.eh_cooling < fjern_targets[3]) {
          image = fjern3
        }
        else {
          image = fjern4
        }
        break
    }
    const monthNames = ["januar", "februar", "marts", "april", "maj", "juni",
      "juli", "august", "september", "oktober", "november", "december"
    ];

    const hoursNames = ["0-1", "1-2", "2-3", "3-4", "4-5", "5-6",
      "6-7", "7-8", "8-9", "9-10", "10-11", "11-12",
      "12-13", "13-14", "14-15", "15-16", "16-17", "17-18",
      "18-19", "19-20", "20-21", "21-22", "22-23", "23-0"
    ];

    let living_date = + from_backend.living_coldest_day + ". " + monthNames[from_backend.living_coldest_month - 1] + " kl. " + hoursNames[from_backend.living_coldest_hour-1]
    let bed_date = + from_backend.bed_coldest_day + ". " + monthNames[from_backend.bed_coldest_month - 1] + " kl. " + hoursNames[from_backend.bed_coldest_hour-1]
    let bath_date = + from_backend.bath_coldest_day + ". " + monthNames[from_backend.bath_coldest_month - 1] + " kl. " + hoursNames[from_backend.bath_coldest_hour-1]

    // console.log(collectedData);

    let debug = sessionStorage["debug"]
    if (debug == undefined) debug = false

    let display_debug = (debug) ? "flex" : "none"

    let heat_type = collectedData.house.heating_type;

    let bar_data = {
      "living_time": from_backend.living_time_percent,
      "living_label": from_backend.living_hours + " time(r)",
      "bed_time": from_backend.bed_time_percent,
      "bed_label": from_backend.bed_hours + " time(r)",
      "bath_time": from_backend.bath_time_percent,
      "bath_label": from_backend.bath_hours + " time(r)"
    }

    let bar_chart_width=500;
    let bar_char_height=300;

    let bar_chart_url = "https://veff.sbi.dk/api/create_hour_graph?data_dict=" + JSON.stringify(bar_data) + "&width=" + bar_chart_width + "&height=" + bar_char_height;

    this.bake_local('bar_chart_url', encodeURIComponent(bar_chart_url));



    let gb_eff = from_backend.gb_eff || NaN
    let gb_fuel_gross = from_backend.gb_fuel_gross || NaN
    let pb_eff = from_backend.pb_eff || NaN
    let pb_fuel_gross = from_backend.pb_fuel_gross || NaN
    let hp_cop = from_backend.hp_cop || NaN
    let hp_gross = from_backend.hp_gross || NaN
    let hp_resist = from_backend.hp_resist || NaN
    let hp_elec_usage = from_backend.hp_elec_usage || NaN
    let eh_electricity = from_backend.eh_electricity / 1000000  || NaN // Wh to mwh
    let eh_water = from_backend.eh_water || NaN
    let eh_cooling = from_backend.eh_cooling || NaN

    let heating_connection_pipes = (collectedData.vvs.pipe_connection.heating_connection_pipes === "Tilstraekkelig") ? "Tilstrækkelig" : collectedData.vvs.pipe_connection.heating_connection_pipes
    let heating_circulation_pump = (collectedData.vvs.pipe_connection.heating_circulation_pump === "Tilstraekkelig") ? "Tilstrækkelig" : collectedData.vvs.pipe_connection.heating_circulation_pump

    this.bake_local("varme", "https://veff.sbi.dk" + image);

    let project_name = this.read_local("name");

    return (
      <div className="top_level">
        <Header site_number="3" />
        <div className="content">

          <Section width="90%">
          <h1 className={style.h1}>{project_name}</h1>
          <hr style={{width: "100%", alignSelf: "center"}}></hr>
            <div className={style.basis}>
           
              <div className={style.dim}>
                <h2 className={style.h2}>Opvarmning</h2>
                <div style={(heat_type !== "Gas") ? { display: "none" } : {}}>
                      <p className={style.p}>Kedelvirkningsgrad: {gb_eff.toFixed(1).toLocaleString('da-DK')} %</p>
                  <p className={style.p}>Gasforbrug: {gb_fuel_gross.toFixed(1).toLocaleString("da-DK")} m&sup3;</p>
                </div>
                <div style={(heat_type !== "Piller") ? { display: "none" } : {}}>
                  <p className={style.p}>Kedelvirkningsgrad: {pb_eff.toFixed(1).toLocaleString('da-DK')} %</p>
                  <p className={style.p}>Pilleforbrug: {pb_fuel_gross.toFixed(1).toLocaleString('da-DK')} kg-piller/år</p>
                </div>
                <div style={(heat_type !== "Varmepumpe") ? { display: "none" } : {}}>
                  <p className={style.p}>Varmepumpe COP: {hp_cop.toFixed(2).toLocaleString('da-DK')}</p>
                  <p className={style.p}>Elforbrug: {(hp_elec_usage/1000).toFixed(0).toLocaleString('da-DK')} kWh/år</p>
                  <p className={style.p}><pre>   - heraf elpatron: {(hp_resist/1000).toFixed(0).toLocaleString('da-DK')} kWh/år</pre></p>
                </div>
                <div style={(heat_type !== "Fjernvarme") ? { display: "none" } : {}}>
                  <p className={style.p}>Energi: {eh_electricity.toFixed(1).toLocaleString('da-DK')} MWh/år</p>
                  <p className={style.p}>Vand: {eh_water.toFixed(1).toLocaleString('da-DK')} m&sup3;/år</p>
                  <p className={style.p}>Afkøling: {eh_cooling.toFixed(1).toLocaleString('da-DK')} °C/år</p>
                </div>
              </div>
              <div className={style.dim}>
                <h2 className={style.h2}>Nettobehov</h2>
                <p className={style.p}>Rumopvarmning: {from_backend.room_heating.toFixed(2).toLocaleString('da-DK')} MWh/år</p>
                <p className={style.p}>Varmt brugsvand: {from_backend.hot_water.toFixed(2).toLocaleString('da-DK')} MWh/år</p>

              </div>
            </div>
            <img src={image} className={style.image} alt="Effektivitets barometer"/>
            <span style={{fontStyle: "italic", fontSize: "smaller"}}>Forbehold for at skalaen kan blive justeret</span>
            <hr style={{width: "100%", alignSelf: "center"}}></hr>
         
<table className={style.table}>
  <tr className={style.tr}>
    <th className={style.th}></th>
    <th className={style.th}>Stue og køkken</th>
    <th className={style.th}>Værelser</th>
    <th className={style.th}>Baderum</th>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Rumtemperatur</td>
    <td className={style.td}>{collectedData.house_pretty.living_temp}</td>
    <td className={style.td}>{collectedData.house_pretty.bed_temp}</td>
    <td className={style.td}>{collectedData.house_pretty.bath_temp}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Varmegivere</td>
    <td className={style.td}>{collectedData.house_pretty.living_heater}</td>
    <td className={style.td}>{collectedData.house_pretty.bed_heater}</td>
    <td className={style.td}>{collectedData.house_pretty.bath_heater}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Natsænkning</td>
    <td className={style.td}>{collectedData.vvs_pretty.room_heating.living_setback}</td>
    <td className={style.td}>{collectedData.vvs_pretty.room_heating.bed_setback}</td>
    <td className={style.td}>{collectedData.vvs_pretty.room_heating.bath_setback}</td>
  </tr>
</table>



<table className={this.state.show_in_two ? style.hidden : style.table}>
  <tr className={style.tr}>
    <th className={style.th}>Fremløbstemperatur</th>
    <th className={style.th}></th>
    <th className={style.th}>Tilslutningsrør</th>
    <th className={style.th}></th>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Hævning</td>
    <td className={style.td}>{collectedData.vvs_pretty.heating_curve.increase_rel}</td>
    <td className={style.td}>Tilslutninger</td>
    <td className={style.td}>{collectedData.vvs_pretty.pipe_connection.heating_connection_pipes}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Natsænkning centralt</td>
    <td className={style.td}>{collectedData.vvs_pretty.heating_curve.setback_rel}</td>
    <td className={style.td}>Cirkulationspumpe</td>
    <td className={style.td}>{collectedData.vvs_pretty.pipe_connection.heating_circulation_pump}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Parallelforskydning</td>
    <td className={style.td}>{collectedData.vvs_pretty.heating_curve.parallel_adjust_rel}</td>
    <td className={style.td}>Omløb</td>
    <td className={style.td}>{collectedData.vvs_pretty.pipe_connection.bypass_rel_flow_setpoint}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Morgenforcering</td>
    <td className={style.td}>{collectedData.vvs_pretty.heating_curve.boost_rel}</td>
    <td className={style.td}>Indreguleret</td>
    <td className={style.td}>{collectedData.vvs_pretty.system_balancing}</td>
  </tr>
</table>

<table className={!this.state.show_in_two ? style.hidden : style.table}>
  <tr className={style.tr}>
    <th className={style.th}>Fremløbstemperatur</th>
    <th className={style.th}></th>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Hævning</td>
    <td className={style.td}>{collectedData.vvs_pretty.heating_curve.increase_rel}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Natsænkning centralt</td>
    <td className={style.td}>{collectedData.vvs_pretty.heating_curve.setback_rel}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Parallelforskydning</td>
    <td className={style.td}>{collectedData.vvs_pretty.heating_curve.parallel_adjust_rel}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Morgenforcering</td>
    <td className={style.td}>{collectedData.vvs_pretty.heating_curve.boost_rel}</td>
  </tr>
  <tr></tr>
  <tr className={style.tr}>
    <th className={style.th}>Tilslutningsrør</th>
    <th className={style.th}></th>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Tilslutninger</td>
    <td className={style.td}>{collectedData.vvs_pretty.pipe_connection.heating_connection_pipes}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Cirkulationspumpe</td>
    <td className={style.td}>{collectedData.vvs_pretty.pipe_connection.heating_circulation_pump}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Omløb</td>
    <td className={style.td}>{collectedData.vvs_pretty.pipe_connection.bypass_rel_flow_setpoint}</td>
  </tr>
  <tr className={style.tr}>
    <td className={style.td}>Indreguleret</td>
    <td className={style.td}>{collectedData.vvs_pretty.system_balancing}</td>
  </tr>
</table>


          </Section>
          <Section width="90%">
            <h2 className={style.h2}>Rumtemperaturer</h2>
            <div className={style.maxdim}>
            <hr style={{width: "50%", alignSelf: "flex-start", marginLeft: "0%", marginRight: "0%"}}></hr>

              <p className={style.p}><b>Stue og køkken:</b> {from_backend.living_hours.toLocaleString('da-DK')} time(r) under {from_backend.living_max_temp.toFixed(1).toLocaleString('da-DK')} °C</p>
              <p className={style.p}>Koldeste time {living_date}</p>
              <p className={style.p}>{from_backend.living_hours_harsh} time(r) under {(from_backend.living_max_temp.toFixed(1) - 1 ).toLocaleString('da-DK')} °C</p>
              <pre className={style.p}>{from_backend.living_indoor_temp.toFixed(1).toLocaleString('da-DK')} °C inde ved en udetemperatur på {from_backend.living_min_temp.toFixed(1).toLocaleString('da-DK')} °C</pre>
              <hr style={{width: "50%", alignSelf: "flex-start", marginLeft: "0%", marginRight: "0%"}}></hr>
              
              <p className={style.p}><b>Værrelser:</b> {from_backend.bed_hours.toLocaleString('da-DK')} time(r) under {from_backend.bed_max_temp.toFixed(1).toLocaleString('da-DK')} °C</p>
              <p className={style.p}>{from_backend.bed_hours_harsh} time(r) under {(from_backend.bed_max_temp.toFixed(1) - 1 ).toLocaleString('da-DK')} °C</p>
              <p className={style.p}>Koldeste time {bed_date}</p>
              <pre className={style.p}>{from_backend.bed_indoor_temp.toFixed(1).toLocaleString('da-DK')} °C inde ved en udetemperatur på {from_backend.bed_min_temp.toFixed(1).toLocaleString('da-DK')} °C</pre>
              <hr style={{width: "50%", alignSelf: "flex-start", marginLeft: "0%", marginRight: "0%"}}></hr>

              <p className={style.p}><b>Badeværrelser:</b> {from_backend.bath_hours.toLocaleString('da-DK')} time(r) under {from_backend.bath_max_temp.toFixed(1).toLocaleString('da-DK')} °C</p>
              <p className={style.p}>{from_backend.bath_hours_harsh} time(r) under {(from_backend.bath_max_temp.toFixed(1) - 1 ).toLocaleString('da-DK')} °C</p>
              <p className={style.p}>Koldeste time {bath_date}</p>
              <pre className={style.p}>{from_backend.bath_indoor_temp.toFixed(1).toLocaleString('da-DK')} °C inde ved en udetemperatur på {from_backend.bath_min_temp.toFixed(1).toLocaleString('da-DK')} °C</pre>
              <hr style={{width: "50%",  alignSelf: "flex-start", marginLeft: "0%", marginRight: "0%"}}></hr>
            </div>
            <img src={bar_chart_url} alt="Timer under temperatur graf" className={style.image} />
            
            <div style={{ display: "flex" }}>
              <CustomButton className="button" text="Forrige" location="/VVSDataPage" />
              <CustomButton className="button" text="Hent PDF" onClick={() => this.get_pdf_data()} />
            </div>
            <div style={{display: display_debug, flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
              <label className={style.name_label}>Debug hour start</label>
                <input id="debug_hour_start" className={style.name_input} style={{marginLeft: 0, marginRight: 0}} type="number" min="0" max="8766"></input>
                <label className={style.name_label}>Debug hour end</label>
                <input id="debug_hour_end" className={style.name_input} style={{marginLeft: 0, marginRight: 0}}type="number" min="0" max="8766"></input>
            <CustomButton className="button" text="Debug" onClick={() => this.get_debug()} />
            </div>
                
          </Section>

        </div>
        <Footer />
        <div className={style.loading_shadow} style={customloadingStyle}>
          <div className={style.sk_folding_cube}>
            <div className={`${style.sk_cube}`}></div>
            <div className={`${style.sk_cube2} ${style.sk_cube}`}></div>
            <div className={`${style.sk_cube4} ${style.sk_cube}`}></div>
            <div className={`${style.sk_cube3} ${style.sk_cube}`}></div>
          </div>
          <div className={style.calctime}>
              <p className={style.p} style={{color: "white"}}>Beregningen kan tage et par minutter</p>
          </div>
        </div>
      </div>

      

    );
  }
}
