import React from 'react';
import '../styling/CustomButton.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class CustomButton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() { 
        return (
                <button className="custom_button" 
                    onClick={ () => this.next_page() }
                >{this.props.text}</button>
        );
    }
    next_page(){
        if (this.props.onClick != undefined) {
            this.props.onClick()
        }
        
        if (this.props.location != undefined) {
            document.location.href = this.props.location;
        } 
            
    }
}