import React from 'react';
import CustomButton from "../components/CustomButton"
import Header from '../components/Header'
import Footer from '../components/Footer'
import style from "../stylemodules/BuildingPage.module.css"
import Section from "../components/Section"
import "../styling/Page.css"
import image from "../images/Burning.jpg"

export default class ErrorPage extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {
    var u = window.location.href
    var url = new URL(u);
    var c = url.searchParams.get("error_message");


    return (
      <div className="top_level">
        <Header site_number="-1" />
        <div className="content">
          <Section width="60%">
                <h2 className={style.h2} style={{alignSelf: "center"}}>Udregningen er brændt sammen!</h2>
                <p className={style.p} style={{alignSelf: "center"}}>{c}</p>
                <img className={style.help_img} src={image}></img>
          </Section>
        </div>
        <Footer/>
      </div>
    );
  }
}