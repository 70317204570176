import React from 'react';
import '../styling/CustomInput.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class CustomInputField extends React.Component {
    constructor(props) {
        super(props);
    }
    render() { 
        return (
            <div class="outerBox">
                <label>{this.props.title}</label>
                <input type="text" class="inputfield" placeholder={this.props.placeholder}></input>
            </div>
        );
    }
}