import React from 'react';
import style from "../stylemodules/CustomDropdown.module.css";

import HelpButton from '../components/HelpButton';
import { throwStatement } from '@babel/types';


export default class CustomDropdown2 extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {text: "hej"}

    componentDidMount() {
        let select = document.getElementById(this.props.id)
        let selected_option_text = select.options[select.selectedIndex].text
        this.setState({text: selected_option_text})
    }

    componentDidUpdate() {
        let select = document.getElementById(this.props.id)
        let selected_option_text = select.options[select.selectedIndex].text
        if (this.state.text !== selected_option_text){
            this.setState({text: selected_option_text})
        }
    }
    
    render() { 
        let label = this.props.label == "outer"
        let visible = (this.props.visible==false) ? "none" : "inline-flex"
        let outer_class = (label) ? style.outerBox2 : style.outerBox
        let label_class = (label) ? style.upperlabel : style.innerlabel
        let hotfix = (label) ? style.not_hotfix : style.select_hotfix
        let hotfix_text = (label) ? style.not_hotfix : style.hotfix_text
        let show_help = label

        let pid = this.props.id + "_p"

        
        return (
            <div className={outer_class} style={{display: visible, width: this.props.width}}>
                <label className={label_class}>{this.props.title}<HelpButton visible={show_help} help_id={this.props.id}></HelpButton></label>
                <div className={hotfix}></div>
                <p className={hotfix_text} id={pid}>{this.state.text}</p>
                <select className={style.dropdown} id={this.props.id} onChange={(e) => {
                    this.setState({text: e.target.options[e.target.selectedIndex].text})
                    if (this.props.onChange) this.props.onChange()}}>
                    {this.props.children}
                </select>
                
            </div>
        );
    }
}