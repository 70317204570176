import React from 'react';
import style from '../stylemodules/Footer.module.css';

export default class Footer extends React.Component {

  componentDidMount () {
    const script = document.createElement("script");

    script.setAttribute("id", "tracker")
    script.src = "/static/tracker.js";
    script.async = true;

    document.body.appendChild(script);
  }

  componentWillUnmount() {
    
  }

  render() {
    return (
      <footer>
          <a href="/About" target="_blank">
            Om veff.sbi.dk
          </a>
          <p>
            © {new Date().getFullYear()} sbi.aau.dk All Rights Reserved
          </p>
      </footer>
    );
  }
}
