import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import HomePage from "./HomePage";
import OptionPage from "./OptionPage";
import EndPage from "./EndPage";
import BuildingInputPage from "./BuildingInputPage";
import VVSDataPage from "./VVSDataPage";
import ResultPage from "./ResultPage";
import HelpPage from "./HelpPage";
import AboutPage from "./AboutPage";
import ErrorPage from "./ErrorPage";

function RouterPage() {
  return (
    <Router>
        <Route path="/" exact component={HomePage} />
        <Route path="/HelpPage" exact component={HelpPage} />
        <Route path="/About" exact component={AboutPage} />
        <Route path="/HomePage" exact component={HomePage} />
        <Route path="/BuildingInputPage" exact component={BuildingInputPage} />
        <Route path="/VVSDataPage" exact component={VVSDataPage} />
        <Route path="/OptionPage/" component={OptionPage} />
        <Route path="/ResultPage/" component={ResultPage} />
        <Route path="/EndPage/" component={EndPage} />
        <Route path="/ErrorPage" component={ErrorPage} />
        <Route path="/tracker.js"/>
    </Router>
  );
}

export default RouterPage;
