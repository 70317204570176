import React from 'react';
import style from "../stylemodules/Section.module.css";

export default class Section extends React.Component {

  constructor(props) {
    super(props)

    this.state = {should_center: true}
  }

  updateDimensions = () => {
    if (window.innerHeight < 500){
      if (window.innerWidth / window.innerHeight > 680/420) {
        this.setState({should_center: false})
    } else {
      this.setState({should_center: true})
    }
    } else {
      if (window.innerWidth / window.innerHeight > 1240/670) {
        this.setState({should_center: false})
    } else {
      this.setState({should_center: true})
    }
    }
    
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {

    let center = (this.props.center == "true" && this.state.should_center) ? "center" : "initial"

    return (
    <div className={style.content_wrapper}>
      <div className={style.section} style={{ width: this.props.width, justifyContent: center}} id="scroller">
      <div style={{width: "100%"}}>
      <div style={{display: "flex",  flexDirection: "column",alignItems: "center"}}>
{this.props.children}
</div>
      </div>
      </div>
    </div>
      
    );
  }
}
