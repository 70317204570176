import React from 'react';
import CustomButton from "../components/CustomButton"
import Header from '../components/Header'
import Footer from '../components/Footer'
import style from "../stylemodules/BuildingPage.module.css"
import Section from "../components/Section"
import "../styling/Page.css"
import image from "../images/front_page.png"

export default class AboutPage extends React.Component {

  constructor(props) {
    super(props)
  }



  render() {
    return (
      <div className="top_level">
        <Header site_number="-1" />
        <div className="content">

          <Section width="60%">
            {/* 
            EDIT BELOW TO CHANGE THE HELP TEXT
            Supported tags are:
                a - for links to the same or a different page
                h2 - for headers
                p - for text
                img - for images
                ul - for defining a list
                li - for each element in the list
                
            id attribute is needed to be able to scroll to an element
            */}

            <h1 className={style.h1}>Om</h1>
            <h2 className={style.h2}>Udvikling og finasiering</h2>
            <p className={style.p}>VEEF-værktøjet er udviklet af Videncenter for Energibesparelser i bygninger. Videncenter for energibesparelser i bygninger er etableret som led i den energipolitiske aftale fra februar 2008 og videreført som en del af energiaftalen fra marts 2012 og igen i december 2015. Videncentret er et center under Energistyrelsen.</p>
            <br/>
            <p className={style.p}>Den samlede drift varetages af et konsortium bestående af:</p>
            <br/>
            <p className={style.p}>Teknologisk Institut, Primetime Kommunikation P/S, Statens Byggeforskningsinstitut/Aalborg Universitet, Viegand Maagøe, DTU</p>
            <br/>
            <h2 className={style.h2}>Formål</h2>
            <p className={style.p}>Værktøjet skal øge VVS-branchens fokus på den samlede varmeinstallations egnethed til ”lavtemperaturdrift”, som er hele forudsætningen for, at der kan opnås optimal energieffektivitet for boligejeren. Samtidig skal værktøjet også belyse konsekvensen af det arbejde VVS-installatøren udføre for boligejeren.</p>
            <br/>
            <p className={style.p}>Som et led i den grønne omstilling bliver huse i højere grad opvarmet med lavere fremløbstemperatur end tidligere. Det sker som en konsekvens af en stigning i varmekildekonverteringer i en-familiehuse til VE, hovedsageligt til fjernvarme eller varmepumpe, som primære energikilder. Udfordringen er dog, at sænkning af fremløbstemperaturen kræver en anderledes anskuelse af klimaskærmen og det eksisterende varmesystem, samt at der desværre opstår en del fejl i varmeinstallationerne.</p>
            <br/>
            <h2 className={style.h2}>Browsere</h2>
            <p className={style.p}>Det anbefales at anvende én af browserne Firefox, Chrome, Safari eller Edge. Ved brug af andre og ældre browsere kan der optræde fejl i beregningen.</p>
            <br/>
            <h2 className={style.h2}>Ansvarsforhold</h2>
            <p className={style.p}>Videncenter for energibesparelser i bygningers vejledning via Besparelsesberegneren er vejledende og dermed ikke juridisk bindende.</p>
            <br/>
            <p className={style.p}>Videncenter for energibesparelser i bygninger og deltagerne i konsortiet bag Videncenter for energibesparelser i bygninger påtager sig intet ansvar for eventuelle fejl og/eller mangler i den nævnte vejledning, uanset om vejledningen er skriftlig eller mundtlig, og dermed intet ansvar for eventuelle, direkte og indirekte, tab, der måtte opstå som følge af dispositioner på baggrund af vejledningen.</p>
            <br/>
            <p className={style.p}>Videncenter for energibesparelser i bygninger og deltagerne i konsortiet bag Videncenter for energibesparelser i bygninger forbeholder sig ret til, når som helst og uden forudgående varsel, helt eller delvist, at revidere dette værktøj samt at tilbagekalde eller begrænse adgangen til hjemmesiden.</p>
            <br/>
            <p className={style.p}>Videncenter for energibesparelser i bygninger og deltagerne i konsortiet bag Videncenter for energibesparelser i bygninger fraskriver sig herudover ethvert ansvar for tekniske fejl på hjemmesiden, herunder nedbrud af eller manglende adgang til hjemmesiden og beskadigelse af data som følge af kommunikationsproblemer i de tekniske systemer, svigt i strømforsyning eller telekommunikationssystemer. Endvidere fraskriver Videncenter for energibesparelser i bygninger og deltagerne i konsortiet bag Videncenter for energibesparelser i bygninger sig ansvar for tredjemands uberettigede adgang (hacking) via hjemmesiden til brugerens eller andres data.</p>
            
          </Section>
        </div>
        <Footer/>
      </div>
    );
  }
}